import React, {useEffect} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonGroup,
    CardMedia,
    Grid,
    TextField,
    Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {actions as eventsOverviewActions, selectors as eventsOverviewSelectors} from "../../../../Ducks/eventsOverview";
import {actions as contactsActions, selectors as ContactsSelectors} from "../../../../Ducks/contacts";
import {actions as opportunityActions, selectors as opportunitySelectors} from "../../../../Ducks/opportunity";
import {bindActionCreators} from "redux";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LanguageIcon from "@mui/icons-material/Language";
import {Phone, LinkedIn, Email, PhoneAndroid, ExpandMore, ChevronLeft, PhoneForwarded, Mail} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {fetchPatch} from "../../../../API/util";
import Swal from "sweetalert2";
import PersonHistory from "../Widgets/PersonHistory";
import {cloneDeep} from "lodash-es";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NextPlannedAction from "../Widgets/NextPlannedAction";
import {isEmpty} from "lodash";
import CasePauseModal from "../Modals/CasePauseModal";
import CaseOngoingModal from "../Modals/CaseOngoingModal";
import BookMeetingModal from "../Modals/BookMeetingModal";
import SimpleTable from "../../../Common/SimpleTable";
import PlannedCallModal from "../Modals/PlannedCallModal";
import ParticipantPurchases from "../../../Common/Overview/ParticipantPurchases";
import logo from "../../../../Assets/icons/f5-logo.svg";
import TagHook from "../../../Common/Tag";
import AlertElement from "../../../Common/Alert";
import {checkIfPersonFoodConsiderationHasChanged} from "../../../Utils/validations";
import {LoadingButton} from "@mui/lab";
import {CURRENT, FUTURE, PRODUCT_TYPE_NETWORK_GROUP} from "../../../Utils/constants";
import {getPendingPersonGroupChange} from "../../../../API/changePersonGroup";
import {selectors as accountSelectors} from "../../../../Ducks/account";

const useStyles = (_theme) => ({
    title: {
        display: "flex !important",
        alignItems: "center !important",
    },
    card: {
        display: "flex !important",
        backgroundColor: "#fff !important",
    },
    cardMedia: {
        flex: 1,
        width: "85px !important",
        backgroundColor: "#F1F1F1 !important",
    },
    cardContent: {
        flex: 4,
        padding: "16px !important",
    },
    flexVertical: {
        display: "flex",
        alignItems: "center",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputField: {
        flex: 1,
    },
    fieldSet: {
        display: "flex",
        alignItems: "end",
    },
    flex: {
        display: "flex",
    },
    sendout: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    send: {
        background: "green",
        width: "14px",
        height: "14px",
        marginRight: "5px",
    },
    sent: {
        background: "#2785e6",
        width: "14px",
        height: "14px",
        marginRight: "5px",
        borderStyle: "dashed",
    },
    notsent: {
        width: "14px",
        height: "14px",
        marginRight: "5px",
        borderStyle: "solid",
    },
    noBgDotted: {
        background: "transparent",
        width: "19px",
        height: "19px",
        marginRight: "5px",
        border: "red",
        borderStyle: "solid",
    },
    lightBlueBgDotted: {
        width: "19px",
        height: "19px",
        marginRight: "5px",
        background: "repeating-linear-gradient(45deg, red, red 2px, #5cd7ff 2px, #5cd7ff 8px)",
        borderStyle: "solid",
        borderColor: "red",
    },
    lightBlueBgSolid: {
        background: "#5cd7ff",
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "#42d142",
    },
    lightBlueBg: {
        background: "#5cd7ff",
        width: "19px",
        height: "19px",
        marginRight: "5px",
    },
    darkBlueBgSolid: {
        background: "#0047ab",
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "#42d142",
    },
    darkBlueBg: {
        background: "#0047ab",
        width: "19px",
        height: "19px",
        marginRight: "5px",
    },
    darkBlueBgDotted: {
        background: "#0047ab",
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "red",
    },
    noBgSolid: {
        background: "transparent",
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
    },
    cyanBgSolid: {
        background: "#FDDA0D",
        width: "19px",
        height: "19px",
        marginRight: "5px",
    },
    cyanBgSolidv2: {
        height: "19px",
        width: "19px",
        borderColor: "red",
        borderStyle: "solid",
        marginRight: "5px",
        background: "repeating-linear-gradient(45deg, red, red 2px, #FDDA0D 2px, #FDDA0D 8px)",
    },
    cyanBgSolidv3: {
        height: "19px",
        width: "19px",
        borderColor: "#42d142",
        borderStyle: "solid",
        marginRight: "5px",
        background: "#FDDA0D",
    },
    cyanBg: {
        background: "#FDDA0D",
        width: "19px",
        height: "19px",
        marginRight: "5px",
    },
    grayBg: {
        background: "#757373",
        width: "19px",
        height: "19px",
        marginRight: "5px",
    },
    purpleBg: {
        background: "purple",
        width: "19px",
        height: "19px",
        marginRight: "5px",
    },
    empty: {
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "#cacaca",
    },
    completed: {
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "green",
        background: "green",
    },
    changed: {
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "red",
        background: "green",
    },
    failed: {
        width: "19px",
        height: "19px",
        marginRight: "5px",
        borderStyle: "solid",
        borderColor: "red",
        background: "red",
    },
    attending: {
        "background": "#68a54d !important",
        "color": "#fff !important",
        "&:hover": {
            background: "#68a54d !important",
        },
    },
    not_attending: {
        "background": "#db6669 !important",
        "color": "#fff !important",
        "&:hover": {
            background: "#db6669 !important",
        },
    },
    unknown: {
        "background": "#7f7f7f !important",
        "color": "#fff !important",
        "&:hover": {
            background: "#7f7f7f !important",
        },
    },
    tentative: {
        "background": "#f9fd01 !important",
        "color": "#000 !important",
        "&:hover": {
            background: "#f9fd01 !important",
        },
    },
    appeared: {
        background: "#2785e6",
        width: "14px",
        height: "14px",
        marginRight: "5px",
        borderStyle: "solid",
    },
    nothing: {
        background: "#2785e6",
        width: "14px",
        height: "14px",
        marginRight: "5px",
    },
    happened: {
        background: "green",
        width: "14px",
        height: "14px",
        marginRight: "5px",
    },
    notHappened: {
        width: "14px",
        height: "14px",
        marginRight: "5px",
        borderStyle: "solid",
    },
    answer: {
        "background": "#1976d2",
        "color": "#fff",
        "&:hover": {
            color: "#000",
        },
    },
    accordionSummary: {
        "& > div": {
            flexDirection: "column",
        },
    },
    historyCount: {
        background: "red",
        borderRadius: "50%",
        color: "#fff",
        fontSize: "12px",
        marginLeft: "5px",
        width: "25px",
        height: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    panel: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "4px",
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        position: "relative",
        overflowAnchor: "none",
        padding: "15px",
    },
    checkbox: {
        width: "15px",
        height: "15px",
        cursor: "pointer",
    },
    personTable: {
        "& > tbody > tr > td": {
            padding: "5px",
            minWidth: "160px",
        },
    },
});

const personColumns = [
    "person_id",
    "erp_client_id",
    "region_id",
    "person_token",
    "person_firstname",
    "person_lastname",
    "person_never_contact",
    "person_mobile_number",
    "person_preferences_never_text",
    "person_preferences_limit_calls",
    "person_linkedin_url",
    "person_linkedin_member_id",
    "person_challenges",
    "person_food_considerations",
    "person_special_conditions",
    "person_industry",
    "person_country",
    "person_region",
    "person_language",
    "person_gender",
    "lead_source",
    "lead_target_id",
    "sf_person_id",
    "person_created_at",
    "person_last_update",
    "person_awaiting_sync",
    "person_last_sync",
    "person_flagged_from_salesview",
];

const attendingStatus = [
    {
        title: "Attending",
        value: "attending",
    },
    {
        title: "Not Attending",
        value: "not_attending",
    },
    {
        title: "Tentative",
        value: "tentative",
    },
    {
        title: "Unknown",
        value: "unknown",
    },
];

const sendOutEmails = [
    {
        title: "Invitation",
        value: "invitation",
    },
    {
        title: "Calendar",
        value: "calendar",
    },
    {
        title: "Placeholder",
        value: "placeholder",
    },
    {
        title: "Reminder email",
        value: "reminder_email",
    },
    {
        title: "Summary email",
        value: "summary_email",
    },
    {
        title: "Reminder sms",
        value: "reminder_sms",
    },
];

const OutboundOverview = (props) => {
    const {classes, item, name} = props;
    var _class = "",
        _changed = false,
        _text = [];
    if (item === undefined) {
        _class = "empty";
    } else if (item[0].mail_queue_status === "complete" && !item.changed) {
        _class = "completed";
    } else if (item[0].mail_queue_status === "completed" && item.changed) {
        _class = "changed";
    } else if (item[0].mail_queue_status.includes("failed")) {
        _class = "failed";
    }

    // Get content and check for status change
    if (item) {
        item.forEach((element) => {
            if (element.changed) {
                _changed = true;
                _text.push(element.created_at + ": " + element.changed_text);
            }
            _text.push(element.mail_queue_attempted_send + ": " + element.mail_queue_status);
        });
    }

    if (item === undefined) {
        return (
            <Tooltip title={name}>
                <div className={classes[_class]}></div>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip
                title={
                    <React.Fragment>
                        <div>{name}</div>
                        <div>
                            {_text.map((text) => (
                                <div>{text}</div>
                            ))}
                        </div>
                    </React.Fragment>
                }
            >
                <div style={_changed ? {border: "1px solid red"} : {border: "none"}} className={classes[_class]}></div>
            </Tooltip>
        );
    }
};

const PersonOverviewTab = (props) => {
    const {
        classes,
        person,
        eventInfo,
        updateAttendanceStatus,
        getContactDetails,
        contactDetails,
        getJobs,
        jobs,
        getPersonPurchases,
        personPurchases,
        getPersonOpportunities,
        personOpportunities,
        getParticipantDetails,
        participantDetails,
        personHistory,
        foodConsiderations,
        isNetworkDirector,
        getPersonOpenOpportunity,
        personOpenOpportunity,
        getPersonPlannedCalls,
        personPlannedCalls,
        handleTabChange,
        toggleSpecialCaseTab,
        getPersonMeetings,
        events,
        opportunity,
        getOpportunityDetails,
        plannedAction,
        opportunityLoading,
        getCalendarLoading,
        updateContactLoading,
        navigate,
        canManageGroupChange
    } = props;

    const [personObj, setPersonObj] = React.useState({});
    const [eventDetails, setEventDetails] = React.useState({});
    const [attendeeDetails, setAttendeeDetails] = React.useState(null);
    const [eventOverview, setEventOverview] = React.useState([]);
    const [_outboundOverview, setOutboundOverview] = React.useState(null);
    const [personDetails, setpersonDetails] = React.useState({});
    const [expanded, setExpanded] = React.useState("panel4");
    const [isOpen, setIsOpen] = React.useState(false);
    const [neverContact, setNeverContact] = React.useState(false);
    const [neverSms, setNeverSms] = React.useState(false);
    const [limitCalls, setLimitCalls] = React.useState(false);
    const [limitEmails, setLimitEmails] = React.useState(false);
    const [personChallenges, setPersonChallenges] = React.useState("");
    const [personFoodConsiderations1, setPersonFoodConsiderations1] = React.useState("17");
    const [personFoodConsiderations2, setPersonFoodConsiderations2] = React.useState([18]);
    const [personFoodConsiderationsOther, setPersonFoodConsiderationsOther] = React.useState("");
    const [personPielineStage, setPersonPielineStage] = React.useState("");
    const [personPielineStages, setPersonPielineStages] = React.useState([]);
    const [action, setAction] = React.useState({});
    const [eventModalOpen, setEventModalOpen] = React.useState(false);
    const [openCaseModal, setOpenCaseModal] = React.useState(false);
    const [bookMeetingModal, setBookMeetingModal] = React.useState(false);
    const [plannedCallModal, setPlannedCallModal] = React.useState(false);
    const [updatePlannedCallModal, setUpdatePlannedCallModal] = React.useState(false);
    const [row, setRow] = React.useState(false);
    const [overviewQuery, setOverviewQuery] = React.useState(null);
    const [updateMeetingModal, setUpdateMeetingModal] = React.useState(false);
    const [personTags, setPersonTags] = React.useState([]);
    const [currentPurchase, setCurrentPurchase] = React.useState(null);
    const [showChangeGroup, setShowChangeGroup] = React.useState(false);

    const edit = {
        update: true,
        remove: false,
    };

    useEffect(() => {
        props.getFoodConsiderations();

        // set query
        const _overviewQuery = getQuery();
        setOverviewQuery(_overviewQuery);
    }, []);

    useEffect(() => {
        if (person !== null && person !== undefined) {
            setPersonObj(person);
            getContactDetails({id: person.person_id, columns: personColumns});
            getJobs(person.person_id);
            getPersonMeetings(person.purchase_id);
            getPersonOpenOpportunity(person.person_id);
            getPersonPurchases(person.person_id);
            getPersonOpportunities(person.person_id);
            getPersonPlannedCalls(person.person_id);
            getChangePersonGroup(person.person_id);
        }

        if (eventInfo !== null && eventInfo !== undefined) {
            setEventDetails(eventInfo);
        }

        if (person !== null && person !== undefined && eventInfo !== null && eventInfo !== undefined) {
            getParticipantDetails({event_id: eventInfo.event_id, participant_id: person.potential_participant_id});
        }
    }, [person, eventInfo]);

    useEffect(() => {
        if (contactDetails !== null && contactDetails !== undefined) {
            setpersonDetails(contactDetails.main[0]);

            // set checkboxes
            setNeverContact(contactDetails.main[0].person_never_contact);
            setNeverSms(contactDetails.main[0].person_preferences_never_text);
            setLimitCalls(contactDetails.main[0].person_preferences_limit_calls);
            setLimitEmails(contactDetails.main[0].person_preferences_limit_emails);


            // set fields
            setPersonChallenges(contactDetails.main[0].person_challenges);

            setPersonFoodConsiderations1(
                (contactDetails.main[0].person_food_considerations_1 && contactDetails.main[0].person_food_considerations_1 !== '')
                    ? contactDetails.main[0].person_food_considerations_1 : "17"
            );
            setPersonFoodConsiderations2(
                (contactDetails.main[0].person_food_considerations_2 && contactDetails.main[0].person_food_considerations_2?.length > 0)
                    ? contactDetails.main[0].person_food_considerations_2 : [18]
            );
            setPersonFoodConsiderationsOther(
                (contactDetails.main[0].person_food_considerations_other && contactDetails.main[0].person_food_considerations_other !== '')
                    ? contactDetails.main[0].person_food_considerations_other : ""
            );
            contactDetails && getChangePersonGroup(contactDetails.main[0].person_id);

        }
    }, [contactDetails]);

    useEffect(() => {
        if (participantDetails) {
            const _eventOverview = cloneDeep(participantDetails.event_overview);
            if (_eventOverview) {
                // Sort
                _eventOverview.sort(function (a, b) {
                    return new Date(a.event_start) - new Date(b.event_start);
                });
                setEventOverview(_eventOverview);
                setOutboundOverview(participantDetails.outbound_overview);
                setAttendeeDetails(participantDetails);
            }
        }
    }, [participantDetails]);

    useEffect(() => {
        if (personOpenOpportunity) {
            if (personOpenOpportunity.opportunity_id) {
                getOpportunityDetails({id: personOpenOpportunity.opportunity_id, preloadNextPrevOpportunity: false});
            }

            setPersonPielineStage(personOpenOpportunity.pipeline_stage_id ? personOpenOpportunity.pipeline_stage_id : "");
            setPersonPielineStages(personOpenOpportunity.pipeline_stages ? personOpenOpportunity.pipeline_stages : []);
            setAction(personOpenOpportunity.opportunity_actions || {});
        }
    }, [personOpenOpportunity]);

    useEffect(() => {
        if (personPurchases) {
            let _currentPurchase = personPurchases.filter(
                (purchase) => (purchase.purchase_actual_status === CURRENT || purchase.purchase_actual_status === FUTURE)
                    && PRODUCT_TYPE_NETWORK_GROUP.indexOf(purchase.product_type_id) !== -1
            );

            if (_currentPurchase.length > 0) {
                setCurrentPurchase(_currentPurchase[0]);
            } else {
                setCurrentPurchase(null);
            }
        }
    }, [personPurchases]);

    const showProgress = () => (
        <div style={{display: "flex", justifyContent: "center"}}>
            <CircularProgress/>
        </div>
    );

    const attendanceStatusUpdate = (item) => {
        const {query} = props;
        const start_date = moment(query.range[0]).format("YYYY-MM-DD");
        const end_date = moment(query.range[1]).format("YYYY-MM-DD");
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`;
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`;
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`;
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&equals[country_id]=${query.country.country_id}`;
        }
        if (query.networkDirector !== "" && query.networkDirector !== null && query.networkDirector !== undefined) {
            _query = `${_query}&equals[network_directors]=${query.networkDirector.id}`;
        }

        const data = {
            participantDetails: {event_id: eventInfo.event_id, participant_id: person.potential_participant_id},
            query: _query,
            obj: {
                event_id: eventDetails.event_id,
                job_id: personObj.job_id,
                attending: item.value,
            },
        };
        updateAttendanceStatus(data);
        setAttendeeDetails(null);
    };

    const getQuery = () => {
        const {query} = props;
        const start_date = moment(query.range[0]).format("YYYY-MM-DD");
        const end_date = moment(query.range[1]).format("YYYY-MM-DD");
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`;
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`;
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`;
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&equals[country_id]=${query.country.country_id}`;
        }
        if (query.networkDirector !== "" && query.networkDirector !== null && query.networkDirector !== undefined) {
            _query = `${_query}&equals[network_directors]=${query.networkDirector.id}`;
        }

        return _query;
    };

    const handleSendoutEmail = (e) => {
        const {query} = props;
        const start_date = moment(query.range[0]).format("YYYY-MM-DD");
        const end_date = moment(query.range[1]).format("YYYY-MM-DD");
        let _query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (query.productType !== "" && query.productType !== null) {
            _query = `${_query}&equals[product_type_id]=${query.productType.product_type_id}`;
        }
        if (query.product !== "" && query.product !== null && query.product !== undefined) {
            _query = `${_query}&equals[product_id]=${query.product.product_id}`;
        }
        if (query.user !== "" && query.user !== null && query.user !== undefined) {
            _query = `${_query}&equals[user_id]=${query.user.user_id}&equals[user_type]=${query.user.user_type}`;
        }
        if (query.country !== "" && query.country !== null && query.country !== undefined) {
            _query = `${_query}&country_id=${query.country.country_id}`;
        }
        const data = {
            query: _query,
            obj: {
                event_id: eventDetails.event_id,
                job_id: personObj.job_id,
                invitation_type: e.target.value,
            },
        };
        fetchPatch(`/email/event/jobs`, data.obj).then((res) => {
            Swal.fire({
                title: "",
                text: res.message,
                icon: res.result ? "success" : "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
            }).then((_result) => {
                // something
            });
        });
    };

    const getStatusClass = (item) => {
        const {event_has_happened, showed, color, has_open_opportunity, purchase_id, freezed, case_status} = item;
        if (freezed && case_status === 'PENDING') {
            return "purpleBg";
        } else if (freezed && case_status === 'APPROVED') {
            return "grayBg"
        } else {
            if (event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
                return "noBgDotted";
            } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
                return "lightBlueBgDotted";
            } else if (event_has_happened && color === "purchase_a" && !has_open_opportunity && showed) {
                return "lightBlueBgSolid";
            } else if (!event_has_happened && color === "purchase_a" && !has_open_opportunity && !showed) {
                return "lightBlueBg";
            } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && showed) {
                return "darkBlueBgSolid";
            } else if (event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
                return "darkBlueBgDotted";
            } else if (!event_has_happened && color === "purchase_b" && !has_open_opportunity && !showed) {
                return "darkBlueBg";
            } else if (!event_has_happened && color === undefined && purchase_id === undefined && showed === undefined) {
                return "noBgSolid";
            } else if (!event_has_happened && color === "purchase_a" && has_open_opportunity && !showed) {
                return "cyanBgSolid";
            } else if (!event_has_happened && color === "purchase_b" && has_open_opportunity && !showed) {
                return "cyanBg";
            } else if (event_has_happened && (color === "purchase_b" || color === "purchase_a") && has_open_opportunity && !showed) {
                return "cyanBgSolidv2";
            } else if (event_has_happened && (color === "purchase_b" || color === "purchase_a") && has_open_opportunity && showed) {
                return "cyanBgSolidv3";
            } else {
                return "noBgSolid";
            }
        }
    };

    const getCompanyName = (companyID) => {
        return jobs.related_objects.companies[companyID].company_name;
    };

    const getYears = (date, end_date) => {
        let d = new Date(date);
        let start = d.getFullYear();

        if (end_date === null) {
            let c = new Date();
            let current = c.getFullYear();
            return Math.ceil(parseInt(current) - parseInt(start));
        } else {
            let e = new Date(end_date);
            let end = e.getFullYear();
            return Math.ceil(parseInt(end) - parseInt(start));
        }
    };

    if (!person) {
        return showProgress();
    }

    const handleChange = (panel) => (_event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (name === "person_challenges") {
            setPersonChallenges(value);
        }

        if (name === "person_never_contact") {
            setNeverContact(value);
        }

        if (name === "person_preferences_never_text") {
            setNeverSms(value);
        }

        if (name === "person_preferences_limit_calls") {
            setLimitCalls(value);
        }

        if (name === "person_preferences_limit_emails") {
            setLimitEmails(value);
        }

        if (name === "tags") {
            setPersonTags(value);
        }
    };

    const saveInfo = () => {
        const personData = {
            id: person.person_id,
            obj: {
                person_challenges: personChallenges,
                person_food_considerations_1: personFoodConsiderations1,
                person_food_considerations_2: personFoodConsiderations2,
                person_food_considerations_other: personFoodConsiderationsOther,
                person_never_contact: neverContact,
                person_preferences_never_text: neverSms,
                person_preferences_limit_calls: limitCalls,
                person_preferences_limit_emails: limitEmails,
                tags: personTags
            },
            columns: personColumns
        }

        const personFoodConsiderationHasChanged =
            checkIfPersonFoodConsiderationHasChanged(personDetails, personData, foodConsiderations, 'event_overview', props);

        if (!personFoodConsiderationHasChanged) {
            props.updateContact(personData);
        }
    };

    const handleTags = (name, value) => {
        setPersonTags(value);
    }

    const getChangePersonGroup = (personId) => {
        getPendingPersonGroupChange(personId).then((res) => {
            if (res?.data?.person_id) {
                setShowChangeGroup(false);
            } else {
                setShowChangeGroup(true);
            }
        }).catch(() => {
            setShowChangeGroup(true);
        });
    }

    const handleChangeGroupClick = () => {
        personDetails && navigate("/contact/id/" + personDetails.person_id + "/change-group");
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{marginBottom: "10px"}}>

                    {
                        personDetails && personDetails.person_food_considerations_set_by_notice !== '' &&
                        <p><AlertElement severity="info"
                                         message={personDetails.person_food_considerations_set_by_notice}/></p>
                    }

                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.cardMedia}
                            component='img'
                            alt=''
                            image={`https://erp.f5.dk/uploads/images/persons/${personDetails.person_token}.jpg`}
                        />
                        <CardContent className={classes.cardContent}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <Typography variant='h6' component='div'>
                                        {personDetails.person_firstname} {personDetails.person_lastname}
                                    </Typography>
                                    {personDetails.title && (
                                        <Typography variant='body2' color='site.main'>
                                            <BusinessCenterIcon sx={{color: "icon.main", fontSize: "20px"}}/>
                                            <span style={{marginLeft: "8px"}}>{personDetails.title}</span>
                                        </Typography>
                                    )}
                                    {personDetails.person_region && (
                                        <Typography variant='body2' color='site.main' className={classes.flexVertical}>
                                            <LanguageIcon sx={{color: "icon.main", fontSize: "20px"}}/>
                                            <span style={{marginLeft: "8px"}}>{personDetails.person_region}</span>
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            <div className={classes.flexCenter}>
                                {personDetails.mobile && (
                                    <Typography variant='body2' color='site.main'>
                                        <a
                                            href={`callto:${personDetails.mobile}`}
                                            onClick={props.showActiveTab}
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <PhoneAndroid sx={{color: "icon.main", fontSize: "20px"}}/>
                                            <span style={{marginLeft: "8px"}}>{personDetails.mobile}</span>
                                        </a>
                                    </Typography>
                                )}
                                {personDetails.email && (
                                    <Typography variant='body2' color='site.link'>
                                        <a
                                            href={`mailto:${personDetails.email}`}
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Email sx={{color: "icon.main", fontSize: "20px"}}/>
                                            <span style={{marginLeft: "8px"}}>{personDetails.email}</span>
                                        </a>
                                    </Typography>
                                )}
                                {currentPurchase && showChangeGroup && canManageGroupChange && (
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                        style={{textTransform: "capitalize"}}
                                        onClick={() => handleChangeGroupClick()}>
                                        Change Group
                                    </Button>
                                )
                                }
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
                                <Typography variant='body2' color='site.main'>
                                    <a target='_blank' href={personDetails.person_linkedin_url}>
                                        <LinkedIn sx={{color: "icon.linkedin", fontSize: "44px"}}/>
                                    </a>
                                </Typography>
                                <Typography variant='body2' color='site.main'>
                                    <a target='_blank'
                                       href={"https://erp.f5.dk/#/contacts/persons/" + personDetails.person_id}>
                                        <img alt='' src={logo} className='logo-link'></img>
                                    </a>
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2}>
                        <Box className={classes.panel}>
                            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <Typography variant='h1' component='h5' sx={{fontSize: "18px", fontWeight: "500"}}>
                                    Personal Details
                                </Typography>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    textAlign: "center"
                                }}>
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginRight: "10px"
                                    }}>
                                        <PhoneAndroid sx={{marginRight: "5px", fontSize: "14px"}}/>{" "}
                                        <a style={{textDecoration: "none", fontSize: "14px"}}
                                           href={`callto:${personDetails.person_mobile_number}`}>
                                            {personDetails.person_mobile_number}
                                        </a>
                                    </Box>
                                    {jobs && jobs.main.length > 0 && jobs.main.filter((item) => item.job_is_primary)[0] && (
                                        <>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginRight: "10px"
                                            }}>
                                                {jobs.main.filter((item) => item.job_is_primary)[0].job_phone !== null &&
                                                jobs.main.filter((item) => item.job_is_primary)[0].job_phone !== "" ? (
                                                    <>
                                                        <PhoneForwarded
                                                            sx={{marginRight: "5px", fontSize: "14px"}}/>{" "}
                                                        <a
                                                            style={{textDecoration: "none", fontSize: "14px"}}
                                                            href={`callto:${jobs.main.filter((item) => item.job_is_primary)[0].job_phone}`}
                                                        >
                                                            {jobs.main.filter((item) => item.job_is_primary)[0].job_phone}
                                                        </a>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </Box>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <Mail sx={{marginRight: "5px", fontSize: "14px"}}/>{" "}
                                                <a
                                                    style={{textDecoration: "none", fontSize: "14px"}}
                                                    href={`mailto:${jobs.main.filter((item) => item.job_is_primary)[0].job_email}`}
                                                >
                                                    {jobs.main.filter((item) => item.job_is_primary)[0].job_email}
                                                </a>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                                <Box sx={{display: "flex", justifyContent: "flex-end", minWidth: "100px"}}>
                                    <ChevronLeft
                                        sx={{cursor: "pointer"}}
                                        className={isOpen ? "showUP" : "showDown"}
                                        onClick={() => setIsOpen(!isOpen)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {isOpen && (
                            <Box className={classes.panel}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <table className={classes.personTable} style={{width: "100%"}}>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Gender
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{
                                                                fontSize: "14px",
                                                                margin: 0,
                                                                textTransform: "capitalize"
                                                            }}>
                                                                {personDetails.person_gender}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    {!isNetworkDirector && (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <Typography
                                                                        variant='h1'
                                                                        component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}
                                                                    >
                                                                        Challenges
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <TextField
                                                                        type='text'
                                                                        id='person_challenges'
                                                                        name="person_challenges"
                                                                        label=''
                                                                        variant='outlined'
                                                                        size='small'
                                                                        style={{width: "100%"}}
                                                                        value={personChallenges}
                                                                        onChange={handleInputChange}
                                                                    ></TextField>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography
                                                                        variant='h1'
                                                                        component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}
                                                                    >
                                                                        Food Considerations
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <FormControl
                                                                        sx={{
                                                                            minWidth: 120,
                                                                            width: "49%",
                                                                            marginRight: "1%"
                                                                        }}
                                                                        size='small'
                                                                    >
                                                                        <InputLabel
                                                                            id='person_food_considerations_1'>Type</InputLabel>
                                                                        <Select
                                                                            labelId='person_food_considerations_1'
                                                                            id='person_food_considerations_1'
                                                                            value={personFoodConsiderations1 == "" ? foodConsiderations.type1_food.find(item => item.id == 17).id : personFoodConsiderations1}
                                                                            label='Type'
                                                                            onChange={(e) => {
                                                                                setPersonFoodConsiderations1(e.target.value);
                                                                            }}
                                                                        >
                                                                            <MenuItem value=''>-- Select
                                                                                Type--</MenuItem>
                                                                            {foodConsiderations &&
                                                                                foodConsiderations.type1_food.map((item) => (
                                                                                    <MenuItem key={item.id}
                                                                                              value={item.id}>
                                                                                        {item.title}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl sx={{minWidth: 120, width: "50%"}}
                                                                                 size='small'>
                                                                        <InputLabel
                                                                            id='person_food_considerations_2'>Allergy</InputLabel>
                                                                        <Select
                                                                            labelId='person_food_considerations_2'
                                                                            id='person_food_considerations_2'
                                                                            multiple
                                                                            value={!personFoodConsiderations2.length ? [18] : personFoodConsiderations2}
                                                                            onChange={(e) => {
                                                                                setPersonFoodConsiderations2(e.target.value);
                                                                                if (e.target.value.includes(18)) {
                                                                                    setPersonFoodConsiderations2((prevArray) => prevArray.filter(item => item !== 18));
                                                                                } else if (!e.target.value.length) {
                                                                                    setPersonFoodConsiderations2([18]);
                                                                                }

                                                                                if (!e.target.value.includes(16)) {
                                                                                    setPersonFoodConsiderationsOther("");
                                                                                }
                                                                            }}
                                                                            input={<OutlinedInput label='Name'/>}
                                                                        >
                                                                            {foodConsiderations &&
                                                                                foodConsiderations.type2_food.map((item) => (
                                                                                    <MenuItem key={item.id}
                                                                                              value={item.id}>
                                                                                        {item.title}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {personFoodConsiderations2.includes(16) && (
                                                                        <TextField
                                                                            type='text'
                                                                            id='food_consideration_other'
                                                                            name='food_consideration_other'
                                                                            label='Other'
                                                                            variant='outlined'
                                                                            size='large'
                                                                            style={{marginTop: "10px", width: "100%"}}
                                                                            value={personFoodConsiderationsOther}
                                                                            onChange={(e) => {
                                                                                setPersonFoodConsiderationsOther(e.target.value);
                                                                            }}
                                                                        ></TextField>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Special Conditions
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                {personDetails.person_special_conditions}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Mobile Number
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                <a href={`callto:${personDetails.person_mobile_number}`}>
                                                                    {personDetails.person_mobile_number}
                                                                </a>
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Connections Count
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                {personDetails.person_li_connection_count}
                                                            </Typography>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Never Contact
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                <input
                                                                    name='person_never_contact'
                                                                    type='checkbox'
                                                                    checked={neverContact}
                                                                    onChange={handleInputChange}
                                                                    className={classes.checkbox}
                                                                    disabled={isNetworkDirector}
                                                                />
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Never SMS
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                <input
                                                                    name='person_preferences_never_text'
                                                                    type='checkbox'
                                                                    checked={neverSms}
                                                                    onChange={handleInputChange}
                                                                    className={classes.checkbox}
                                                                    disabled={isNetworkDirector}
                                                                />
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Limit Calls
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                <input
                                                                    name='person_preferences_limit_calls'
                                                                    type='checkbox'
                                                                    checked={limitCalls}
                                                                    onChange={handleInputChange}
                                                                    className={classes.checkbox}
                                                                    disabled={isNetworkDirector}
                                                                />
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Limit Emails
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                <input
                                                                    name='person_preferences_limit_emails'
                                                                    type='checkbox'
                                                                    checked={limitEmails}
                                                                    onChange={handleInputChange}
                                                                    className={classes.checkbox}
                                                                    disabled={true}
                                                                />
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                CC Email
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                <a href={`mailto:${personDetails.person_cc_email}`}>
                                                                    {personDetails.person_cc_email}
                                                                </a>
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                LinkedIn MemberID
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                {personDetails.person_linkedin_member_id}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Lead Source
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                {personDetails.lead_source}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant='h1' component='h5'
                                                                        sx={{fontSize: "14px", fontWeight: "bold"}}>
                                                                Last Scrape Time
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography sx={{fontSize: "14px", margin: 0}}>
                                                                {personDetails.person_last_scrape_time}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography variant="h1" component="h5"
                                                                        sx={{fontSize: '14px', fontWeight: 'bold'}}>
                                                                Tags
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <TagHook tags={props.Tags} setValue={handleTags}
                                                                     selectedTags={personDetails.tags} edit={false}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <LoadingButton
                                                                loading={updateContactLoading}
                                                                color='primary'
                                                                variant='contained'
                                                                sx={{
                                                                    display: "block",
                                                                    width: "100%",
                                                                    marginTop: "10px"
                                                                }}
                                                                onClick={saveInfo}
                                                            >
                                                                Save
                                                            </LoadingButton>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Box>
                </Grid>
                {!attendeeDetails ? (
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                        <CircularProgress/>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <div className={classes.flex}>
                                {attendeeDetails &&
                                    eventOverview.length > 0 &&
                                    eventOverview.map((item, index) => {
                                        const color = getStatusClass(item);
                                        return (
                                            <Tooltip
                                                key={index}
                                                title={
                                                    <React.Fragment>
                                                        <div>{item.event_name}</div>
                                                        <div>Date: {moment(item.event_start).format("DD/MM/YYYY HH:mm")}</div>
                                                        <div>
                                                            {item.event_has_happened ? (
                                                                <>
                                                                    {item.rating_nps ? <div>NPS
                                                                        Rating: {item.rating_nps}</div> : <></>}{" "}
                                                                    {item.rating_overall ? <div>Overall
                                                                        Rating: {item.rating_overall}</div> : <></>}{" "}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <div className={classes[color]}></div>
                                            </Tooltip>
                                        );
                                    })}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.sendout}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Typography component='span' style={{marginRight: "10px"}}>
                                        <b>Sendout Overview</b>
                                    </Typography>
                                    <div className={classes.flex}>
                                        {attendeeDetails &&
                                        attendeeDetails.outbound_overview &&
                                        attendeeDetails.outbound_overview.event_calendar_ph_email.length ? (
                                            <OutboundOverview
                                                item={attendeeDetails.outbound_overview.event_calendar_ph_email}
                                                name='Calendar Placeholder Invitation'
                                                classes={classes}
                                            />
                                        ) : (
                                            <OutboundOverview name='Calendar Placeholder Invitation' classes={classes}/>
                                        )}
                                        {attendeeDetails &&
                                        attendeeDetails.outbound_overview &&
                                        attendeeDetails.outbound_overview.event_calendar_email.length ? (
                                            <OutboundOverview
                                                item={attendeeDetails.outbound_overview.event_calendar_email}
                                                name='Calendar Invitation'
                                                classes={classes}
                                            />
                                        ) : (
                                            <OutboundOverview name='Calendar Invitation' classes={classes}/>
                                        )}
                                        {attendeeDetails &&
                                        attendeeDetails.outbound_overview &&
                                        attendeeDetails.outbound_overview.event_invitation_email.length ? (
                                            <OutboundOverview
                                                item={attendeeDetails.outbound_overview.event_invitation_email}
                                                name='Invitation Email'
                                                classes={classes}
                                            />
                                        ) : (
                                            <OutboundOverview name='Invitation Email' classes={classes}/>
                                        )}
                                        {attendeeDetails &&
                                        attendeeDetails.outbound_overview &&
                                        attendeeDetails.outbound_overview.event_reminder_email.length ? (
                                            <OutboundOverview
                                                item={attendeeDetails.outbound_overview.event_reminder_email}
                                                name='Reminder Email'
                                                classes={classes}
                                            />
                                        ) : (
                                            <OutboundOverview name='Reminder Email' classes={classes}/>
                                        )}
                                        {attendeeDetails &&
                                        attendeeDetails.outbound_overview &&
                                        attendeeDetails.outbound_overview.event_reminder_sms.length ? (
                                            <OutboundOverview
                                                item={attendeeDetails.outbound_overview.event_reminder_sms}
                                                name='Reminder SMS'
                                                classes={classes}
                                            />
                                        ) : (
                                            <OutboundOverview name='Reminder SMS' classes={classes}/>
                                        )}
                                        {attendeeDetails &&
                                        attendeeDetails.outbound_overview &&
                                        attendeeDetails.outbound_overview.event_summary_email.length ? (
                                            <OutboundOverview
                                                item={attendeeDetails.outbound_overview.event_summary_email}
                                                name='Summary Email'
                                                classes={classes}
                                            />
                                        ) : (
                                            <OutboundOverview name='Summary Email' classes={classes}/>
                                        )}
                                    </div>
                                </div>
                                {!isNetworkDirector && (
                                    <div style={{marginLeft: "0px"}}>
                                        <select style={{minWidth: "180px"}} className={classes.selectBox}
                                                onChange={handleSendoutEmail}>
                                            <option value=''>-- Select --</option>
                                            {sendOutEmails.map((email, index) => {
                                                return (
                                                    <option key={index} value={email.value}>
                                                        {email.title}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}
                                {!isNetworkDirector && !personDetails.special_case_status && (
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <ButtonGroup size='small' variant='contained'
                                                     aria-label='outlined primary button group'>
                                            <Button style={{textTransform: "capitalize"}}
                                                    onClick={() => setOpenCaseModal(true)}>
                                                Pause
                                            </Button>
                                            <Button style={{textTransform: "capitalize"}}
                                                    onClick={() => setEventModalOpen(true)}>
                                                Special Case
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                )}
                                {!isNetworkDirector && personDetails.special_case_status && (
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <Button
                                            style={{
                                                textTransform: "capitalize",
                                                backgroundColor: "purple",
                                                color: "#fff"
                                            }}
                                            onClick={(e) => handleTabChange(e, "special case")}
                                        >
                                            {personDetails.special_case_status.toLowerCase() === "pause" ? "Paused" : "Ongoing"}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.sendout}>
                                <Typography component='span' style={{marginRight: "10px"}}>
                                    <b>Attending Status</b>
                                </Typography>
                                <div className={classes.flexCenter}>
                                    {attendingStatus.map((item, index) => {
                                        const selected =
                                            participantDetails && participantDetails.answer && participantDetails.answer.value === item.value
                                                ? item.value
                                                : "";
                                        return (
                                            <Button
                                                disabled={eventInfo.event_attendance_done !== false || isNetworkDirector}
                                                className={classes[selected]}
                                                color='inherit'
                                                variant='contained'
                                                key={index + 200}
                                                sx={{marginRight: "10px"}}
                                                onClick={(e) => attendanceStatusUpdate(item)}
                                            >
                                                {item.title}
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>
                        </Grid>
                    </>
                )}
                {personOpenOpportunity && !isNetworkDirector && !isEmpty(personOpenOpportunity) && (
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <NextPlannedAction
                                    opportunity={personOpenOpportunity}
                                    person={person}
                                    opportunityDetails={opportunity}
                                    events={events}
                                    plannedAction={plannedAction}
                                    opportunityLoading={opportunityLoading}
                                    getCalendarLoading={getCalendarLoading}
                                />

                                <div style={{
                                    borderTop: "1px solid #c8c8c8",
                                    padding: "10px 0",
                                    margin: "10px 0 0"
                                }}></div>

                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div style={{marginRight: "10px", display: "flex", flexDirection: "column"}}>
										<span>
											<b>Current Open Opportunity:</b>{" "}
                                            {personOpenOpportunity && personOpenOpportunity.opportunity_id ? (
                                                <a
                                                    href={`${window.location.origin}/opportunities/id/${
                                                        personOpenOpportunity ? personOpenOpportunity.opportunity_id : undefined
                                                    }`}
                                                    target='_blank'
                                                >
                                                    {personOpenOpportunity && personOpenOpportunity.opportunity_id}
                                                </a>
                                            ) : (
                                                "None"
                                            )}
										</span>
                                        <span
                                            style={{fontSize: "15px"}}>({personOpenOpportunity && personOpenOpportunity.sales_rep_name})</span>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Typography component='span' style={{marginRight: "10px"}}>
                                            <b>Pipeline Stage:</b>
                                        </Typography>
                                        <FormControl size='small' sx={{minWidth: "200px"}}>
                                            <InputLabel id='person_pipeline_stage'>Pipeline Stage</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId='person_pipeline_stage'
                                                id='person_pipeline_stage'
                                                value={personPielineStage}
                                                onChange={(e) => {
                                                    const selected_stage = personOpenOpportunity.pipeline_stages.filter(
                                                        (item) => item.pipeline_stage_id === e.target.value
                                                    )[0];
                                                    const current_stage = personOpenOpportunity.pipeline_stages.filter(
                                                        (item) => item.pipeline_stage_id === personPielineStage
                                                    )[0];
                                                    Swal.fire({
                                                        customClass: {
                                                            title: "custom-swal-title",
                                                            confirmButton: "custom-swal-confirm",
                                                        },
                                                        title: "You are going to change the stage of the opportunity",
                                                        html: `${current_stage.pipeline_stage_title} &#8594 ${selected_stage.pipeline_stage_title}`,
                                                        width: "600px",
                                                        showCancelButton: true,
                                                        confirmButtonColor: "#e0e0e0",
                                                        cancelButtonColor: "#d33",
                                                        confirmButtonText: "Confirm",
                                                        cancelButtonText: "Cancel",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            setPersonPielineStage(e.target.value);
                                                            props.updatePersonStage({
                                                                person_id: person.person_id,
                                                                opportunityId: personOpenOpportunity.opportunity_id,
                                                                stageId: e.target.value,
                                                            });
                                                        }
                                                    });
                                                }}
                                                input={<OutlinedInput label='Pipeline Stage'/>}
                                            >
                                                {personPielineStages.map((item) => (
                                                    <MenuItem key={item.pipeline_stage_id}
                                                              value={item.pipeline_stage_id}>
                                                        {item.pipeline_stage_title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {!isNetworkDirector && personPlannedCalls && personPlannedCalls.length > 0 && (
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore/>} aria-controls='panel5a-content'
                                              id='panel5a-header'>
                                <Typography
                                    variant='h1'
                                    component='h5'
                                    sx={{display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "500"}}
                                >
                                    Follow up Actions <span
                                    className={classes.historyCount}>{personPlannedCalls && personPlannedCalls.length}</span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{background: "#efefef"}}>
                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        style={{textTransform: "capitalize", marginBottom: "10px"}}
                                        onClick={() => setPlannedCallModal(true)}
                                    >
                                        Create follow up action
                                    </Button>
                                </div>
                                <SimpleTable
                                    editing={edit}
                                    editKey='planned_call_id'
                                    beautify={true}
                                    rows={personPlannedCalls}
                                    columnHeader={["Due Time", "Reason", "Status", "Actions"]}
                                    columnKeys={["planned_call_due_time", "planned_call_reason", "planned_call_status", "actions"]}
                                    openModal={setUpdatePlannedCallModal}
                                    row={setRow}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls='panel2a-content'
                            id='panel2a-header'
                            className={classes.accordionSummary}
                        >
                            <Typography variant='h1' component='h5'
                                        sx={{fontSize: "18px", fontWeight: "500", marginBottom: "10px"}}>
                                Jobs
                            </Typography>
                            {jobs &&
                                jobs.main
                                    .filter((item) => item.job_is_primary)
                                    .map((job, index) => {
                                        return (
                                            <Grid container spacing={2} key={index}>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: "13px"}} gutterBottom variant='h6'
                                                                component='div'>
                                                        {job.division && getCompanyName(job.division.company_id)}
                                                    </Typography>

                                                    <Typography
                                                        style={{flexGrow: "1", fontSize: "11px"}}
                                                        gutterBottom
                                                        variant='span'
                                                        component='span'
                                                    >
                                                        {moment(job.job_start).format("DD/MM/YYYY")} -{" "}
                                                        {job.job_stop === null ? (
                                                            <>
                                                                Present - {getYears(job.job_start, null)}
                                                                <span>years</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {moment(job.job_stop).format("DD/MM/YYYY")} - {getYears(job.job_start, job.job_stop)}{" "}
                                                                <span>years</span>
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={{padding: "0 10px 0 0", fontSize: "13px"}}
                                                        gutterBottom
                                                        className={classes.typography}
                                                        variant='span'
                                                        component='span'
                                                    >
                                                        {job.job_title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {job.job_phone !== "" ? (
                                                        <Typography
                                                            sx={{
                                                                padding: "0 20px 0 0",
                                                                fontSize: "13px",
                                                            }}
                                                            gutterBottom
                                                            variant='div'
                                                            component='div'
                                                        >
                                                            <a
                                                                href={`callto:${job.job_phone}`}
                                                                onClick={props.showActiveTab}
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: "inherit",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
																<span style={{marginRight: "5px", lineHeight: "1px"}}>
																	<Phone sx={{color: "icon.main", fontSize: "15px"}}/>
																</span>
                                                                <span>{job.job_phone}</span>
                                                            </a>
                                                        </Typography>
                                                    ) : (
                                                        <div style={{flex: 1}}></div>
                                                    )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {job.job_email !== "" ? (
                                                        <Typography
                                                            sx={{
                                                                padding: "0 20px 0 0",
                                                                fontSize: "13px",
                                                                color: "site.link",
                                                            }}
                                                            gutterBottom
                                                            variant='div'
                                                            component='div'
                                                        >
                                                            <a
                                                                href={`mailto:${personObj.email}`}
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: "inherit",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
																<span style={{marginRight: "5px", lineHeight: "1px"}}>
																	<Email sx={{color: "icon.main", fontSize: "18px"}}/>
																</span>
                                                                <span>{job.job_email}</span>
                                                            </a>
                                                        </Typography>
                                                    ) : (
                                                        <div style={{flex: 2}}></div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                        </AccordionSummary>
                        <AccordionDetails>
                            {jobs &&
                                jobs.main
                                    .filter((item) => !item.job_is_primary)
                                    .map((job, _index) => {
                                        return (
                                            <Grid container spacing={2} sx={{
                                                padding: "10px 0",
                                                borderTop: "1px solid #c2c2c2",
                                                marginTop: 0
                                            }}>
                                                <Grid item xs={4} sx={{paddingTop: "0 !important"}}>
                                                    <Typography style={{fontSize: "13px"}} gutterBottom variant='h6'
                                                                component='div'>
                                                        {job.division && getCompanyName(job.division.company_id)}
                                                    </Typography>

                                                    <Typography
                                                        style={{flexGrow: "1", fontSize: "11px"}}
                                                        gutterBottom
                                                        variant='span'
                                                        component='span'
                                                    >
                                                        {moment(job.job_start).format("DD/MM/YYYY")} -{" "}
                                                        {job.job_stop === null ? (
                                                            <>
                                                                Present - {getYears(job.job_start, null)}
                                                                <span>years</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {moment(job.job_stop).format("DD/MM/YYYY")} - {getYears(job.job_start, job.job_stop)}{" "}
                                                                <span>years</span>
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sx={{paddingTop: "0 !important"}}>
                                                    <Typography
                                                        style={{padding: "0 10px 0 0", fontSize: "13px"}}
                                                        gutterBottom
                                                        className={classes.typography}
                                                        variant='span'
                                                        component='span'
                                                    >
                                                        {job.job_title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sx={{paddingTop: "0 !important"}}>
                                                    {job.job_phone !== "" ? (
                                                        <Typography
                                                            sx={{
                                                                padding: "0 20px 0 0",
                                                                fontSize: "13px",
                                                            }}
                                                            gutterBottom
                                                            variant='div'
                                                            component='div'
                                                        >
                                                            <a
                                                                href={`callto:${job.job_phone}`}
                                                                onClick={props.showActiveTab}
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: "inherit",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
																<span style={{marginRight: "5px", lineHeight: "1px"}}>
																	<Phone sx={{color: "icon.main", fontSize: "15px"}}/>
																</span>
                                                                <span>{job.job_phone}</span>
                                                            </a>
                                                        </Typography>
                                                    ) : (
                                                        <div style={{flex: 1}}></div>
                                                    )}
                                                </Grid>
                                                <Grid item xs={4} sx={{paddingTop: "0 !important"}}>
                                                    {job.job_email !== "" ? (
                                                        <Typography
                                                            sx={{
                                                                padding: "0 20px 0 0",
                                                                fontSize: "13px",
                                                                color: "site.link",
                                                            }}
                                                            gutterBottom
                                                            variant='div'
                                                            component='div'
                                                        >
                                                            <a
                                                                href={`mailto:${personObj.email}`}
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: "inherit",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
																<span style={{marginRight: "5px", lineHeight: "1px"}}>
																	<Email sx={{color: "icon.main", fontSize: "18px"}}/>
																</span>
                                                                <span>{job.job_email}</span>
                                                            </a>
                                                        </Typography>
                                                    ) : (
                                                        <div style={{flex: 2}}></div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <ParticipantPurchases classes={classes} personPurchases={personPurchases}/>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>} aria-controls='panel3a-content'
                                          id='panel3a-header'>
                            <Typography variant='h1' component='h5' sx={{fontSize: "18px", fontWeight: "500"}}>
                                Opportunities
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {personOpportunities &&
                                personOpportunities.map((opportunity, index) => {
                                    return (
                                        <Grid
                                            key={index}
                                            container
                                            spacing={2}
                                            sx={{padding: "10px 0", borderTop: "1px solid #c2c2c2", marginTop: 0}}
                                        >
                                            <Grid item xs={6} sx={{paddingTop: "0 !important"}}>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <Typography style={{fontSize: "13px"}} gutterBottom variant='h6'
                                                                component='div'>
                                                        {opportunity.product_name}
                                                    </Typography>
                                                    <Typography
                                                        style={{flexGrow: "1", fontSize: "11px"}}
                                                        gutterBottom
                                                        variant='span'
                                                        component='span'
                                                    >
                                                        {opportunity.product_type_name}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} sx={{paddingTop: "0 !important"}}>
                                                <Typography style={{fontSize: "13px"}} gutterBottom variant='h6'
                                                            component='div'>
                                                    {opportunity.opportunity_status}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sx={{paddingTop: "0 !important"}}>
                                                <Typography
                                                    style={{padding: "0 10px 0 0", fontSize: "13px"}}
                                                    gutterBottom
                                                    className={classes.typography}
                                                    variant='span'
                                                    component='span'
                                                >
                                                    {opportunity.opportunity_type}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sx={{paddingTop: "0 !important"}}>
                                                <Typography
                                                    style={{padding: "0 10px 0 0", fontSize: "13px"}}
                                                    gutterBottom
                                                    className={classes.typography}
                                                    variant='span'
                                                    component='span'
                                                >
                                                    {moment(opportunity.opportunity_creation_time).format("DD/MM/YYYY hh:mm")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                        <AccordionSummary expandIcon={<ExpandMore/>} aria-controls='panel4a-content'
                                          id='panel4a-header'>
                            <Typography
                                variant='h1'
                                component='h5'
                                sx={{display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "500"}}
                            >
                                History <span
                                className={classes.historyCount}>{personHistory && personHistory.length}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{background: "#efefef"}}>
                            <PersonHistory person={personObj} isNetworkDirector={isNetworkDirector}/>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <CaseOngoingModal
                open={eventModalOpen}
                handleClose={() => setEventModalOpen(false)}
                startDate={action.opportunity_action_due_time}
                opportunity={personOpenOpportunity}
                action={action}
                person={person}
                editEvent={action.opportunity_action_due_time ? true : false}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                toggleSpecialCaseTab={toggleSpecialCaseTab}
            />
            <CasePauseModal
                open={openCaseModal}
                handleClose={() => setOpenCaseModal(false)}
                person={person}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                toggleSpecialCaseTab={toggleSpecialCaseTab}
            />
            <BookMeetingModal
                open={bookMeetingModal}
                handleClose={() => setBookMeetingModal(false)}
                person={person}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                toggleSpecialCaseTab={toggleSpecialCaseTab}
            />
            <BookMeetingModal
                open={updateMeetingModal}
                handleClose={() => setUpdateMeetingModal(false)}
                person={person}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                row={row}
                edit={true}
            />
            <PlannedCallModal
                open={plannedCallModal}
                handleClose={() => setPlannedCallModal(false)}
                person={person}
                users={props.users}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                reset={Math.random()}
                toggleSpecialCaseTab={toggleSpecialCaseTab}
            />
            <PlannedCallModal
                open={updatePlannedCallModal}
                handleClose={() => setUpdatePlannedCallModal(false)}
                person={person}
                users={props.users}
                event_id={eventInfo.event_id}
                participant_id={person.potential_participant_id}
                query={overviewQuery}
                columns={personColumns}
                edit={true}
                row={row}
                toggleSpecialCaseTab={toggleSpecialCaseTab}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    personPurchases: eventsOverviewSelectors.personPurchases(state),
    personOpportunities: eventsOverviewSelectors.personOpportunities(state),
    personPlannedCalls: eventsOverviewSelectors.personPlannedCalls(state),
    participantDetails: eventsOverviewSelectors.participantDetails(state),
    personMeetings: eventsOverviewSelectors.personMeetings(state),
    contactDetails: ContactsSelectors.contactDetails(state),
    foodConsiderations: ContactsSelectors.foodConsiderations(state),
    jobs: opportunitySelectors.jobs(state),
    personHistory: eventsOverviewSelectors.personHistory(state),
    personOpenOpportunity: eventsOverviewSelectors.personOpenOpportunity(state),
    events: opportunitySelectors.events(state),
    opportunity: opportunitySelectors.opportunity(state),
    plannedAction: opportunitySelectors.plannedActionResponse(state),
    opportunityLoading: opportunitySelectors.opportunity_loading(state),
    getCalendarLoading: opportunitySelectors.getCalendar_loading(state),
    Tags: ContactsSelectors.Tags(state),
    updateContactLoading: ContactsSelectors.updateContactLoading(state),
    canManageGroupChange: accountSelectors.canManageGroupChange(state)
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateAttendanceStatus: eventsOverviewActions.updateAttendanceStatus,
            updateBookedMeeting: eventsOverviewActions.updateBookedMeeting,
            updateSendoutEmail: eventsOverviewActions.updateSendoutEmail,
            updatePersonStage: eventsOverviewActions.updatePersonStage,
            getPersonPurchases: eventsOverviewActions.getPersonPurchases,
            getPersonOpenOpportunity: eventsOverviewActions.getPersonOpenOpportunity,
            getPersonOpportunities: eventsOverviewActions.getPersonOpportunities,
            getPersonPlannedCalls: eventsOverviewActions.getPersonPlannedCalls,
            getParticipantDetails: eventsOverviewActions.getParticipantDetails,
            getPersonMeetings: eventsOverviewActions.getPersonMeetings,
            getContactDetails: contactsActions.getContactDetails,
            getFoodConsiderations: contactsActions.getFoodConsiderations,
            getJobs: opportunityActions.getJobs,
            updateContact: contactsActions.updateContact,
            getOpportunityDetails: opportunityActions.getOpportunityDetails,
        },
        dispatch
    );

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <PersonOverviewTab {...props} params={params} navigate={navigate}/>;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
