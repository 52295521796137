import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import {Stepper, Step, StepLabel, IconButton, Tooltip} from "@mui/material";
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {styled} from '@mui/material/styles';
import {
    ArrowCircleLeft, ArrowCircleRight,
    AttachMoney,
    Event,
} from "@mui/icons-material";
import PurchaseOverview from "./PurchaseOverview";
import {deleteChangePersonGroup, getChangePersonGroup, processChangePersonGroup} from "../../../API/changePersonGroup";
import PurchaseEventsOverview from "./PurchaseEventsOverview";
import Grid from "@mui/material/Grid";
import ChangePersonGroupOverview from "./ChangePersonGroupOverview";
import ResaleOpportunityOverview from "./ResaleOpportunityOverview";
import {addDays} from "date-fns";
import {dateToString} from "../../Utils/dateUtils";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import * as React from "react";

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: '#002EA7'
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: '#002EA7'
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#C7C7C7',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#C7C7C7',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        background: '#002EA7',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        background: '#002EA7',
    }),
}));

function ColorlibStepIcon(props) {
    const {active, completed, className} = props;

    const icons = {
        1: <AttachMoney/>,
        2: <Event/>,
        3: <AttachMoney/>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const ChangePersonGroupDetails = (props) => {
    const {navigate} = props;
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState({});
    const [newPurchaseEvents, setNewPurchaseEvents] = useState([]);
    const [newResaleEvents, setNewResaleEvents] = useState([]);
    const [newNextResaleEvents, setNewNextResaleEvents] = useState([]);
    const [connectedResale, setConnectedResale] = useState(null);
    const [openResaleNextToResoldPurchase, setOpenResaleNextToResoldPurchase] = useState(null);
    const [newPurchase, setNewPurchase] = useState({});
    const [meetingsToBeAdded, setMeetingsToBeAdded] = useState('');
    const [newPurchaseStop, setNewPurchaseStop] = useState('');
    const [connectedResalePurchaseStart, setConnectedResalePurchaseStart] = useState(null);
    const [connectedResalePurchaseStop, setConnectedResalePurchaseStop] = useState(null);
    const [nextConnectedResalePurchaseStart, setNextConnectedResalePurchaseStart] = useState(null);
    const [nextConnectedResalePurchaseStop, setNextConnectedResalePurchaseStop] = useState(null);
    const [plannedAction, setPlannedAction] = useState(null);
    const [nextConnectedResalePlannedAction, setNextConnectedResalePlannedAction] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [firstMeetingDate, setFirstMeetingDate] = useState(null);
    const [selectedNewPurchaseEvents, setSelectedNewPurchaseEvents] = useState([]);
    const [errors, setErrors] = useState(null);
    const steps = ['Purchase Overview', 'Events Overview', 'Resale Overview'];

    const getChangePersonGroupDetails = () => {
        let changePersonGroupId = props.params.id;

        setLoading(true);
        getChangePersonGroup(changePersonGroupId).then((res) => {
            if (res.data) {
                const _selectedEvents = res.data.new_upcoming_events.filter((e) => e.event_start >= res.data.first_meeting_date).splice(0, res.data.meetings_left);
                setData(res.data);
                setMeetingsToBeAdded(res.data.meetings_left);
                setFirstMeetingDate(res.data.first_meeting_date);
                adjustUpcomingAndResaleEvents(res.data.meetings_left, res.data, _selectedEvents);
            }

            setLoading(false);
        }).catch((_error) => {
            setLoading(false);
        });
    };

    const selectedEventReference = (event) => {
        if (event.length) {
            setFirstMeetingDate(event[0].event_start);
            setSelectedNewPurchaseEvents(event);
            adjustUpcomingAndResaleEvents(event.length, data, event, event[0].event_start)
        }
    }

    const nextConnectedResalePurchase = (data, newUpcomingEvents, _meetingsCount, newResaleEvents) => {
        if (data.future_purchases?.length > 1) {
            let _openPurchaseNextToResold = data.future_purchases[1];

            if (_openPurchaseNextToResold?.product?.product_type_id === data.product_type_id) {
                setOpenResaleNextToResoldPurchase(_openPurchaseNextToResold);

                const _newNextResaleEvents = newUpcomingEvents.filter((e) => e.event_start > newResaleEvents[newResaleEvents.length - 1]?.event_start);
                setNewNextResaleEvents(_newNextResaleEvents);

                if (_newNextResaleEvents?.length) {
                    let nextPurchaseStop = new Date(_newNextResaleEvents[_newNextResaleEvents.length - 1]?.event_start);

                    setNextConnectedResalePurchaseStop(nextPurchaseStop);
                }
            }
        }
    }
    const purchaseOverviewSubmit = (purchase, errors) => {
        setNewPurchase(purchase);
        setErrors(errors);
    };
    const handleNextClick = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackClick = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleProcessClick = () => {
        let postData = {};

        postData['purchase'] = newPurchase;
        postData['purchase']['purchase_id'] = data?.purchase?.purchase_id; //old purchase id is required to remove old events
        postData['purchase']['erp_client_division_id'] = data?.purchase?.erp_client_division_id; //required to create purchase
        postData['purchase']['job_id'] = data?.purchase?.job_id; //required to create purchase
        postData['purchase']['purchase_description'] = data?.new_product_description; //description of new product will be purchase description
        postData['purchase']['purchase_challenges'] = data?.purchase?.purchase_challenges; //required to create purchase
        postData['purchase']['purchase_special_conditions'] = data?.purchase?.purchase_special_conditions; //required to create purchase

        if (data?.purchase?.special_case) {
            postData['purchase']['special_case'] = data?.purchase?.special_case;
        }

        postData['purchase']['old_events'] = data?.purchase?.events?.map(({
                                                                              event_to_product_id,
                                                                              event_id,
                                                                              event_name,
                                                                              event_start,
                                                                              event_stop
                                                                          }) => ({
            event_to_product_id,
            event_id,
            event_name,
            event_start,
            event_stop
        }));
        postData['purchase']['events'] = selectedNewPurchaseEvents.map(({
                                                                            event_to_product_id,
                                                                            event_id,
                                                                            event_name,
                                                                            event_start,
                                                                            event_stop
                                                                        }) => ({
            event_to_product_id,
            event_id,
            event_name,
            event_start,
            event_stop
        }));

        if (connectedResale) {
            postData['resale'] = {
                'opportunity_id': connectedResale?.opportunity?.opportunity_id,
                'product_id': data.new_product_id,
                'purchase_id': connectedResale.purchase_id,
                'purchase_description': data?.new_product_description, //description of new product will be purchase description
                'purchase_start': dateToString(new Date(connectedResalePurchaseStart), 'yyyy-MM-dd'),
                'purchase_stop': dateToString(new Date(connectedResalePurchaseStop), 'yyyy-MM-dd'),
                'events': newResaleEvents?.map(({
                                                    event_to_product_id,
                                                    event_id,
                                                    event_name,
                                                    event_start,
                                                    event_stop
                                                }) => ({
                    event_to_product_id,
                    event_id,
                    event_name,
                    event_start,
                    event_stop
                }))
            };
        }

        if (openResaleNextToResoldPurchase) {
            postData['next_resale'] = {
                'opportunity_id': openResaleNextToResoldPurchase?.opportunity?.opportunity_id,
                'product_id': data.new_product_id,
                'purchase_id': openResaleNextToResoldPurchase.purchase_id,
                'purchase_description': data?.new_product_description, //description of new product will be purchase description
                'purchase_start': dateToString(new Date(nextConnectedResalePurchaseStart), 'yyyy-MM-dd'),
                'purchase_stop': dateToString(new Date(nextConnectedResalePurchaseStop), 'yyyy-MM-dd'),
                'events': newNextResaleEvents?.map(({
                                                        event_to_product_id,
                                                        event_id,
                                                        event_name,
                                                        event_start,
                                                        event_stop
                                                    }) => ({
                    event_to_product_id,
                    event_id,
                    event_name,
                    event_start,
                    event_stop
                }))
            };
        }

        delete postData['purchase']['product_name'];
        delete postData['purchase']['person_id'];
        delete postData['']
        setProcessing(true);
        processChangePersonGroup(id, postData).then((res) => {
            toast.success(res.message);
            setProcessing(false);
            navigate("/change-member-group");
        }).catch((error) => {
            toast.error(error);
            setProcessing(false);
        });
    }

    const handleDeleteClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let changePersonGroupId = props.params.id;

                setDeleting(true);
                deleteChangePersonGroup(changePersonGroupId).then((res) => {
                    toast.success(res.message);
                    setDeleting(false);
                    navigate("/change-member-group");
                }).catch((error) => {
                    toast.error(error);
                    setDeleting(false);
                });
            }
        });
    }

    const handleCancelClick = () => {
        navigate("/change-member-group");
    }

    const handleMeetingsCountChange = (meetingsCount) => {
        setMeetingsToBeAdded(meetingsCount);
    }

    const adjustUpcomingAndResaleEvents = (meetingsCount, changePersonGroup, preSelectedEvents) => {
        let newUpcomingEvents = changePersonGroup.new_upcoming_events;
        setNewPurchaseEvents(newUpcomingEvents);
        let lastEventDate = null;

        if (preSelectedEvents?.length) {
            lastEventDate = new Date(preSelectedEvents[preSelectedEvents.length - 1].event_start);
            lastEventDate = addDays(lastEventDate, 1);

            setConnectedResalePurchaseStart(lastEventDate);

            if (preSelectedEvents.length > 1) {
                let _plannedAction = new Date(preSelectedEvents[preSelectedEvents.length - 2].event_start);
                _plannedAction = addDays(_plannedAction, 1);
                _plannedAction = dateToString(_plannedAction, 'dd/MM/yyyy');

                setPlannedAction(_plannedAction + " 10:00");
            }
        }

        let _errors = {...errors};
        let _newPurchaseStop = newUpcomingEvents.find(event => new Date(event.event_start) > lastEventDate);

        if (_newPurchaseStop) {
            let newPurchaseStopDate = new Date(_newPurchaseStop.event_start);
            newPurchaseStopDate = addDays(newPurchaseStopDate, -1);
            setNewPurchaseStop(newPurchaseStopDate);
        } else {
            setNewPurchaseStop(null);
            if (errors && !errors.hasOwnProperty('purchase_stop')) {
                _errors.purchase_stop =  'Required';
                setErrors(_errors);
            }
        }

        let meetingsAssignedCount = meetingsCount;

        if (changePersonGroup.future_purchases?.length > 0) {
            let _futurePurchase = changePersonGroup.future_purchases[0];

            //product type id of current purchase should match with future purchase, we will not change person
            //group for BI instance products.
            if (_futurePurchase?.product?.product_type_id === changePersonGroup.product_type_id) {
                const upcomingEventsCount = _futurePurchase.upcoming_events?.length;
                meetingsAssignedCount += upcomingEventsCount;
                let _newResaleEvents = newUpcomingEvents.filter((e) => e.event_start > preSelectedEvents[preSelectedEvents.length - 1].event_start).slice(0, 5);
                setNewResaleEvents(_newResaleEvents);


                if (_newResaleEvents?.length) {
                    let purchaseStop = new Date(_newResaleEvents[_newResaleEvents.length - 1].event_start);

                    setConnectedResalePurchaseStop(purchaseStop);

                    purchaseStop = addDays(purchaseStop, 1);

                    setNextConnectedResalePurchaseStart(purchaseStop);

                    if (_newResaleEvents.length > 1) {
                        let _nextConnectedResalePlannedAction = new Date(_newResaleEvents[_newResaleEvents.length - 2].event_start);
                        _nextConnectedResalePlannedAction = addDays(_nextConnectedResalePlannedAction, 1);
                        _nextConnectedResalePlannedAction = dateToString(_nextConnectedResalePlannedAction, 'dd/MM/yyyy');

                        setNextConnectedResalePlannedAction(_nextConnectedResalePlannedAction + " 10:00");
                    }
                }

                if (_futurePurchase?.product?.product_id === changePersonGroup.old_product_id) {
                    //product id check with old_product id ensures connected purchase belongs to same group,so
                    //this should also be updated accordingly.
                    if (_futurePurchase?.opportunity?.opportunity_status === 'open') {
                        setConnectedResale(_futurePurchase);
                    } else if (_futurePurchase?.opportunity?.opportunity_status === 'closed:won') {
                        setConnectedResale(_futurePurchase);

                        nextConnectedResalePurchase(changePersonGroup, newUpcomingEvents, meetingsAssignedCount, _newResaleEvents);
                    }
                } else if (_futurePurchase?.product?.product_id === changePersonGroup.new_product_id) {
                    //product id check with new_product id ensures connected purchase belongs to new group,so
                    //events in current purchase will be added before resold membership events.
                    if (_futurePurchase?.opportunity?.opportunity_status === 'closed:won') {
                        setConnectedResale(_futurePurchase);

                        nextConnectedResalePurchase(changePersonGroup, newUpcomingEvents, meetingsAssignedCount, _newResaleEvents);
                    }
                }
            }
        }
    }

    const meetingsSelected = () => {
        return meetingsToBeAdded > 0;
    }

    useEffect(() => {
        getChangePersonGroupDetails();
    }, []);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "50px"
                }}
            >
                <h2 className='page-title'>Member Changes Group Overview</h2>
            </div>

            <Grid container>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={1}
                              style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Tooltip title={'Back'} placement="top" arrow>
                                <IconButton
                                    variant="contained"
                                    color="error"
                                    disabled={activeStep === 0}
                                    onClick={handleBackClick}>
                                    <ArrowCircleLeft fontSize="large"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={10} sx={{marginTop: '15px'}}>
                            <Stepper activeStep={activeStep} connector={<ColorlibConnector/>}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Tooltip title={'Next'} placement="top" arrow>
                                <IconButton
                                    variant="contained"
                                    onClick={handleNextClick}
                                    color="primary"
                                    disabled={activeStep === (steps.length - 1) || (activeStep === 1 && !meetingsSelected())}>
                                    <ArrowCircleRight fontSize="large"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <>
                        {
                            activeStep === 0 &&
                            <PurchaseOverview data={data} purchaseOverviewSubmit={purchaseOverviewSubmit}
                                              newPurchaseStop={newPurchaseStop}
                            />
                        }
                        {
                            activeStep === 1 &&
                            <PurchaseEventsOverview oldEvents={data?.purchase?.events}
                                                    newEvents={newPurchaseEvents}
                                                    specialCase={data?.purchase?.special_case}
                                                    initialMeetingsCount={meetingsToBeAdded}
                                                    handleMeetingsCountChange={handleMeetingsCountChange}
                                                    selectedEventReference={selectedEventReference}
                                                    firstMeetingDate={firstMeetingDate}
                                                    errors={errors}
                                                    setErrors={setErrors}/>
                        }
                        {
                            activeStep === 2 &&
                            <ResaleOpportunityOverview data={data?.future_purchases} newResaleEvents={newResaleEvents}
                                                       connectedResale={connectedResale}
                                                       connectedResalePurchaseStart={connectedResalePurchaseStart}
                                                       connectedResalePurchaseStop={connectedResalePurchaseStop}
                                                       openResaleNextToResoldPurchase={openResaleNextToResoldPurchase}
                                                       newNextResaleEvents={newNextResaleEvents}
                                                       nextConnectedResalePurchaseStart={nextConnectedResalePurchaseStart}
                                                       nextConnectedResalePurchaseStop={nextConnectedResalePurchaseStop}
                                                       plannedAction={plannedAction}
                                                       nextConnectedResalePlannedAction={nextConnectedResalePlannedAction}/>
                        }

                    </>
                </Grid>
                <Grid item xs={4}>
                    <ChangePersonGroupOverview data={data} loading={loading} processing={processing}
                                               deleting={deleting}
                                               meetingsToBeAdded={meetingsToBeAdded}
                                               handleProcessClick={handleProcessClick}
                                               handleCancelClick={handleCancelClick}
                                               handleDeleteClick={handleDeleteClick}
                                               firstMeetingDate={firstMeetingDate}
                                               selectedNewPurchaseEvents={selectedNewPurchaseEvents}
                                               errors={errors}/>
                </Grid>
            </Grid>

        </div>
    );
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <ChangePersonGroupDetails {...props} params={params} navigate={navigate}/>;
}

export default (WithNavigate)