import React, {useEffect, useRef, useState} from "react";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {selectors as contactsSelectors} from "../../../Ducks/contacts";
import {selectors as accountSelectors} from "../../../Ducks/account";
import {
    Autocomplete,
    Button,
    Card,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    TextField as MaterialTextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {fetchGet, fetchPatch, fetchPost} from "../../../API/util";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Alert from "../../Common/Alert";
import {Delete, Info} from "@mui/icons-material";
import {
    DK_STANDARD_LAYOUT,
    INVOICE_STATUS_ARCHIVED_OR_INVOICED,
    INVOICE_STATUS_DRAFT,
    INVOICE_STATUS_PENDING,
    INVOICE_TYPE_INVOICE,
    INVOICE_TYPE_ORDER,
    PAYMENT_TERM_BETALES_INDEN,
    PERSON_NAME_MERGE_TAG, RESALE,
} from "../../Utils/constants";
import {replaceMergeTag} from "../../Utils/stringUtils";
import {convertToDecimalNumber} from "../../Utils/dateUtils";
import {getEconomicResaleProducts, getLayouts, getPaymentTerms, getProducts} from "../../../API/invoice";

toast.configure();

const useStyles = (_theme) => ({
    container: {
        textAlign: "left",
    },
    tabs: {
        marginTop: "24px",
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        position: "relative",
        zIndex: 999,
    },
    headline: {
        margin: "0",
        fontWeight: "100",
        fontSize: "26px",
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    headlineSpan: {
        margin: "0",
        fontWeight: "100",
        fontSize: "26px",
        position: "relative",
        flex: 1,
        display: "flex",
        alignItems: "center",
    },
    user: {
        fontSize: "15px",
        fontWeight: 500,
        marginBottom: "5px",
    },
    stepper: {
        display: "flex",
        alignItems: "center",
        flex: 2,
        marginTop: "3px",
        marginBottom: "5px",
    },
    status: {
        color: "#fff",
        fontSize: "12px",
        textTransform: "uppercase",
        fontWeight: "400",
        padding: "3px 10px",
        borderRadius: "4px",
    },
    opportunityID: {
        margin: "0 5px",
    },
    tabsStyling: {
        padding: "12px 0 !important",
        backgroundColor: "#f5f5f5 !important",
        color: "#3a3641 !important",
        border: "1px solid #dcdbdb !important",
        opacity: "1",
        fontWeight: "600 !important",
        flex: "1 !important",
        textTransform: "capitalize !important",
    },
    switcher: {
        display: "flex !important",
        justifyContent: "flex-end !important",
        margin: "-3px 0 0 10px !important",
    },
    switcherBtn: {
        padding: "0 !important",
        marginRight: "5px !important",
        minWidth: "auto !important",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        zIndex: 99999,
    },
});

const InvoiceDetails = (props) => {
    const {classes, navigate, canDraftInvoice} = props;
    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [clickedButton, setClickedButton] = useState(null);
    const [layoutOptions, setLayoutOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
    const [economicResaleProducts, setEconomicResaleProducts] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [disableSplitInvoice, setDisableSplitInvoice] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        loadLayouts();
        loadProducts();
        loadPaymentTerms();
        loadData();
        loadEconomicResaleProducts();
    }, []);

    const loadData = () => {
        setLoading(true);
        let api = `/invoices/${id}`;
        fetchGet(api)
            .then((invoice) => {
                setLoading(false);
                setData(invoice);
                if (invoice) {
                    populateForm(invoice);
                }
            })
            .catch((error) => {
                toast.error(error);
                setLoading(false);
            });
    };

    const loadLayouts = () => {
        getLayouts()
            .then((layouts) => {
                if (layouts.data) {
                    setLayoutOptions(layouts.data);
                }
            })
            .catch((error) => {
                toast.error(error?.message);
            });
    };

    const loadProducts = () => {
        getProducts()
            .then((products) => {
                let _products = products.data.filter((item) => !item.product_name.includes("INAKTIV"));
                setProductOptions(_products);
                setFilteredProducts(_products);
            })
            .catch((error) => {
                toast.error(error);
            });
    };

    const loadPaymentTerms = () => {
        getPaymentTerms()
            .then((res) => {
                if (res.data) {
                    setPaymentTermsOptions(res.data);
                }
            })
            .catch((error) => {
                toast.error(error);
            });
    }

    const loadEconomicResaleProducts = () => {
        getEconomicResaleProducts().then((res) => {
            if (res?.data) {
                setEconomicResaleProducts(res.data);
            }
        }).catch((error) => toast.error(error.message));
    }

    const populateForm = (invoice) => {
        formRef.current.setFieldValue("company_cvr", invoice.opportunity?.opportunity_cvr);
        formRef.current.setFieldValue("company_name", invoice.override_company_name);
        formRef.current.setFieldValue("company_address_1", invoice.opportunity?.opportunity_address_1);
        formRef.current.setFieldValue("company_zip_code", invoice.opportunity?.opportunity_address_postal_code);
        formRef.current.setFieldValue("company_city", invoice.opportunity?.opportunity_address_city);
        formRef.current.setFieldValue("company_address_country", invoice.opportunity?.opportunity_address_country);
        formRef.current.setFieldValue("purchase_id", invoice.purchase_id);
        formRef.current.setFieldValue("purchase_type", invoice.purchase_type);
        formRef.current.setFieldValue("purchase_price", invoice.purchase_price);
        formRef.current.setFieldValue("purchase_bookkeeper_email", invoice.purchase_bookkeeper_email);
        formRef.current.setFieldValue("purchase_ean", invoice.purchase_ean);
        formRef.current.setFieldValue("purchase_po_number", invoice.purchase_po_number);
        formRef.current.setFieldValue("purchase_creation_time", invoice.purchase_creation_time);
        formRef.current.setFieldValue("invoice_creation_date", invoice.invoice_creation_date);
        formRef.current.setFieldValue("product_heading", invoice.product_heading);
        formRef.current.setFieldValue("invoice_number", invoice.invoice_number);
        formRef.current.setFieldValue("invoice_payment_terms_type", invoice.invoice_payment_terms_type);
        formRef.current.setFieldValue("document_type", invoice.document_type);
        formRef.current.setFieldValue("invoice_status", invoice.invoice_status);
        formRef.current.setFieldValue("invoice_draft_date", invoice.invoice_draft_date);
        formRef.current.setFieldValue("invoice_deadline_date", invoice.invoice_deadline_date);
        formRef.current.setFieldValue("invoice_amount_currency", invoice.invoice_amount_currency);
        formRef.current.setFieldValue("additional_notes", invoice.additional_notes);
        formRef.current.setFieldValue("invoice_notes", invoice.invoice_notes);
        formRef.current.setFieldValue("invoice_footer_notes", invoice.invoice_footer_notes);
        formRef.current.setFieldValue(
            "agent",
            invoice.opportunity.pipeline.user.user_firstname + " " + invoice.opportunity.pipeline.user.user_lastname
        );
    };

    const handleButtonClick = (buttonId) => {
        setClickedButton(buttonId);
    };

    const handleInvoicePercentageInput = (event, index) => {
        const {value} = event.target;
        event.target.value = value.replace(/[^0-9.]/g, ""); // Remove any character that is not a digit or a dot
        let percent = parseFloat(event.target.value);

        if (isNaN(percent)) {
            percent = 0;
        }

        let amount = (data.purchase_price * percent) / 100;
        formRef.current.setFieldValue(`split_invoices[${index}].invoice_amount`, amount);
        let totalPercent = formRef.current.values.invoice_percentage
            ? formRef.current.values.invoice_percentage.reduce((sum, currentValue, currentIndex) => {
                if (currentIndex === index) {
                    return sum;
                }
                return sum + parseFloat(currentValue);
            }, 0)
            : 0;
        totalPercent = parseFloat(totalPercent) + parseFloat(percent);

        if (totalPercent > 100) {
            let difference = totalPercent - 100;
            let previousPercent = formRef.current.values.invoice_percentage[index - 1];
            let newPreviousPercent = previousPercent - difference;
            let newPreviousAmount = (newPreviousPercent * data.purchase_price) / 100;

            formRef.current.setFieldValue(`invoice_percentage[${index - 1}]`, newPreviousPercent);
            formRef.current.setFieldValue(`split_invoices[${index - 1}].invoice_amount`, newPreviousAmount);
        }

        //if percentage is 0 or undefined then do not allow to split invoice.
        if (percent === undefined || percent === null || percent === 0) {
            totalPercent = 101; //making totalPercent greater than 100 to disable split button.
        }

        disableSplitInvoiceButton(totalPercent);
    };

    const handleInvoiceAmountInput = (event, index) => {
        const {value} = event.target;

        event.target.value = value
            .replace(/[^0-9.]/g, "") // Remove any character that is not a digit or a dot
            .replace(/(\..*?)\..*/g, "$1"); // Allow only one dot

        const amount = event.target.value;

        let percent = (amount * 100) / data.purchase_price;
        formRef.current.setFieldValue(`invoice_percentage[${index}]`, convertToDecimalNumber(percent));

        let totalPercent = formRef.current.values.invoice_percentage
            ? formRef.current.values.split_invoices.reduce((sum, currentValue, currentIndex) => {
                if (currentIndex === index) {
                    return sum;
                }
                return sum + currentValue;
            }, 0)
            : 0;

        if (percent === undefined || percent === null || percent === 0) {
            totalPercent = 101; //making totalPercent greater than 100 to disable split button.
        } else {
            totalPercent = parseFloat(totalPercent) + parseFloat(percent);
        }

        disableSplitInvoiceButton(totalPercent);
    };

    const handleSplitInvoiceClick = (push) => {
        let invoiceAmounts = formRef.current.values.split_invoices;
        let totalAmount = invoiceAmounts.reduce((sum, currentValue) => sum + parseFloat(currentValue.invoice_amount), 0);
        let leftAmount = data.purchase_price - totalAmount;

        let invoicePercentage = formRef.current.values.invoice_percentage;
        let percent = (leftAmount * 100) / data.purchase_price;
        invoicePercentage.push(convertToDecimalNumber(percent));
        formRef.current.setFieldValue("invoice_percentage", invoicePercentage);

        push({
            invoice_payment_terms: "",
            invoice_amount: leftAmount,
            invoice_sent_date: moment(),
            invoice_due_date: moment()
        });

        disableSplitInvoiceButton(100);
        prepareInvoiceNotes(formRef.current.values.purchase_po_number, formRef.current.values.invoice_notes, invoicePercentage);
    };

    const handleDeleteClick = (remove, index) => {
        let previousIndexAmount = 0;

        formRef.current.values.split_invoices.forEach((invoice, invoiceIndex) => {

            if (invoiceIndex !== index && invoiceIndex !== index - 1) {
                previousIndexAmount += parseFloat(invoice.invoice_amount);
            }
        });

        previousIndexAmount = parseFloat(data.purchase_price) - previousIndexAmount;

        formRef.current.setFieldValue(`split_invoices[${index - 1}].invoice_amount`, previousIndexAmount);

        let previousIndexPercentUpdated = (previousIndexAmount * 100) / data.purchase_price;
        let invoicePercentages = formRef.current.values.invoice_percentage;
        invoicePercentages[index - 1] = convertToDecimalNumber(previousIndexPercentUpdated);

        invoicePercentages.splice(index, 1);
        formRef.current.setFieldValue(`invoice_percentage`, invoicePercentages);
        remove(index);
        prepareInvoiceNotes(formRef.current.values.purchase_po_number, formRef.current.values.invoice_notes, invoicePercentages);
    };

    const disableSplitInvoiceButton = (totalPercent) => {
        if (totalPercent >= 100) {
            setDisableSplitInvoice(true);
        } else {
            setDisableSplitInvoice(false);
        }
    };

    const disableInvoiceForm = (isSubmitting, values) => {
        return (
            isSubmitting ||
            (values.document_type === INVOICE_TYPE_INVOICE && values.invoice_status !== INVOICE_STATUS_PENDING) ||
            (values.document_type === INVOICE_TYPE_ORDER && values.invoice_status === INVOICE_STATUS_ARCHIVED_OR_INVOICED)
        );
    };

    const disableSaveAndDraftButton = (isSubmitting, values, _user) => {
        return (
            isSubmitting ||
            (values.document_type === INVOICE_TYPE_INVOICE && values.invoice_status !== INVOICE_STATUS_PENDING) ||
            (values.document_type === INVOICE_TYPE_ORDER && values.invoice_status === INVOICE_STATUS_ARCHIVED_OR_INVOICED) ||
            !canDraftInvoice
        );
    };

    const mergeSplitInvoices = (checked, values) => {
        if (checked) {
            //merge all invoices if split into multiple.
            let _splitInvoices = values.split_invoices;

            _splitInvoices = _splitInvoices.filter((invoice) => invoice.parent_id === 0);

            if (_splitInvoices.length === 1) {
                _splitInvoices[0].invoice_amount = data.purchase_price;
            }

            let invoicePercentage = [100];

            formRef.current.setFieldValue("split_invoices", _splitInvoices);
            formRef.current.setFieldValue("invoice_percentage", invoicePercentage);
            prepareInvoiceNotes(formRef.current.values.purchase_po_number, formRef.current.values.invoice_notes, invoicePercentage);
        }
    };

    const prepareInvoiceNotes = (poNumber, invoiceNotes, splitInvoices) => {

        if (invoiceNotes?.length) {
            invoiceNotes = invoiceNotes.split('\n');
            let poNumberPattern = /po[- ]*number:/ig;
            let poNumberFound = false;
            let i = 0;

            let splitPaymentPattern = /del 1 ud/ig;
            let splitPaymentFound = false;

            for (i = 0; i < invoiceNotes.length; i++) {
                let notesLine = invoiceNotes[i];
                let matched = notesLine.match(poNumberPattern);

                if (matched?.length) {
                    if (poNumber?.length) {
                        invoiceNotes[i] = matched + " " + poNumber;
                    } else {
                        invoiceNotes.splice(i, 1);
                        i--;
                    }

                    poNumberFound = true;
                } else {
                    matched = notesLine.match(splitPaymentPattern);

                    if (matched?.length) {
                        if (splitInvoices?.length > 1) {
                            invoiceNotes[i] = "Del 1 ud " + splitInvoices.length;
                        } else {
                            invoiceNotes.splice(i, 1);
                            i--;
                        }

                        splitPaymentFound = true;
                    }
                }
            }

            if (!splitPaymentFound) {
                if (splitInvoices?.length > 1) {
                    invoiceNotes.push('Del 1 ud ' + splitInvoices.length);
                }
            }

            if (!poNumberFound) {
                if (poNumber?.length) {
                    invoiceNotes.push("PO-number: " + poNumber);
                }
            }

            invoiceNotes = invoiceNotes.join("\n");
            formRef.current.setFieldValue('invoice_notes', invoiceNotes);
        } else {
            let invoiceNotes = [];

            if (splitInvoices?.length > 1) {
                invoiceNotes.push("Del 1 ud " + splitInvoices.length);
            }

            if (poNumber?.length) {
                invoiceNotes.push("PO-number: " + poNumber);
            }

            formRef.current.setFieldValue('invoice_notes', invoiceNotes.join("\n"));
        }

        if (poNumber !== formRef.current.values.purchase_po_number) {
            formRef.current.setFieldValue("purchase_po_number", poNumber);
        }
    }

    const handleSubmit = (values, setSubmitting) => {
        let postData = {
            purchase: {
                purchase_bookkeeper_email: values.purchase_bookkeeper_email ? values.purchase_bookkeeper_email.trim() : "",
                purchase_ean: values.purchase_ean ? values.purchase_ean.trim() : "",
                purchase_po_number: values.purchase_po_number ? values.purchase_po_number.trim() : "",
            },
            invoice: {
                invoice_id: id,
                additional_notes: values.additional_notes ? values.additional_notes.trim() : "",
                invoice_notes: values.invoice_notes ? values.invoice_notes.trim() : "",
                invoice_footer_notes: values.invoice_footer_notes ? values.invoice_footer_notes.trim() : "",
                invoice_layout: values.invoice_layout,
                split_invoices: values.split_invoices,
                document_type: values.document_type,
            },
            product: {
                product_id: values.product_id,
                product_name: values.product_name
            },
            invoice_layout_title: values.invoice_layout_title,
            user_economic_id: values.user_economic_id,
        };

        values.split_invoices.forEach((inv, index) => {
            const sentDate = moment(inv.invoice_sent_date);
            postData["invoice"]["split_invoices"][index].invoice_sent_date = sentDate.format("YYYY-MM-DD");

            if (inv.invoice_payment_terms === PAYMENT_TERM_BETALES_INDEN && inv.invoice_due_date) {
                const dueDate = moment(inv.invoice_due_date);
                postData["invoice"]["split_invoices"][index].invoice_due_date = dueDate.format("YYYY-MM-DD");
            } else {
                postData["invoice"]["split_invoices"][index].invoice_due_date = null;
            }
        });

        if (clickedButton === "save") {
            let api = `/invoices/${id}`;
            fetchPatch(api, postData)
                .then((invoice) => {
                    if (invoice) {
                        toast.success("Invoice has been updated successfully");
                    }
                    setSubmitting(false);
                })
                .catch((error) => {
                    setSubmitting(false);
                    toast.error(error.message);
                });
        } else {
            let api = `/invoices/${id}/draft`;
            fetchPost(api, postData)
                .then((_result) => {
                    if (values.document_type === INVOICE_TYPE_INVOICE) {
                        toast.success("Invoice draft is in progress");
                    } else if (values.document_type === INVOICE_TYPE_ORDER) {
                        toast.success("Order draft is in progress");
                    }

                    navigate("/finance");
                    setSubmitting(false);
                })
                .catch((error) => {
                    setSubmitting(false);
                    if (error.code === 422) {
                        setShowAlert(true);
                        setErrorMsg(error.message);
                        toast.error("Error occurred while drafting invoice");
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    }

    useEffect(() => {
        if (productOptions?.length && data) {
            if (data.purchase_type === "resale" && economicResaleProducts?.length) {
                let resaleEconomicProductIds = economicResaleProducts.map((item) => item.economic_product_id);
                let _filteredProducts = productOptions.filter((item) => resaleEconomicProductIds.includes(item.product_id));
                setFilteredProducts(_filteredProducts);
            }
        }
    }, [data, productOptions, economicResaleProducts]);

    useEffect(() => {
        if (paymentTermsOptions?.length && data) {
            let _totalPercent = 0;

            if (data?.split_invoices) {
                let _splitInvoices = [...data?.split_invoices];

                _splitInvoices?.forEach((invoice, index) => {
                    let paymentTermFound = paymentTermsOptions.find((term) => term.name === invoice.invoice_payment_terms);

                    if (!paymentTermFound) {
                        invoice.invoice_payment_terms = "";
                    }

                    const percent = (invoice.invoice_amount / data.purchase_price) * 100;

                    formRef.current?.setFieldValue(`invoice_percentage[${index}]`, parseInt(percent));
                    formRef.current?.setFieldTouched(`invoice_percentage[${index}]`, true);

                    _totalPercent += parseInt(percent);

                    if (data.invoice_status === INVOICE_STATUS_PENDING) {
                        invoice.invoice_sent_date = new Date();
                    }
                })
                formRef.current?.setFieldValue('split_invoices', _splitInvoices);
            }


            disableSplitInvoiceButton(_totalPercent);
        }
    }, [data, paymentTermsOptions]);

    useEffect(() => {
        if (layoutOptions && layoutOptions.length && data) {
            let _layout = layoutOptions.find((option) => option.layoutNumber === data.invoice_layout);

            if (formRef && formRef.current) {
                if (_layout) {
                    formRef.current.setFieldValue("invoice_layout", _layout?.layoutNumber);
                    formRef.current.setFieldValue("invoice_layout_title", _layout.name);
                } else {
                    _layout = layoutOptions.find((option) => option.name === DK_STANDARD_LAYOUT);
                    formRef.current.setFieldValue("invoice_layout", _layout?.layoutNumber);
                    formRef.current.setFieldValue("invoice_layout_title", _layout?.name);
                }
            }
        }
    }, [data, layoutOptions]);

    useEffect(() => {
        if (data && filteredProducts && filteredProducts.length) {
                let _matchedProduct = filteredProducts.filter((item) => item.product_id === data.invoice_economic_product_id);
                let person_name = data.person_firstname + " " + data.person_lastname;

                if (_matchedProduct && _matchedProduct.length > 0) {
                    formRef.current.setFieldValue("product", _matchedProduct[0]);
                    formRef.current.setFieldValue("product_id", _matchedProduct[0].product_id);
                    formRef.current.setFieldValue("product_name", replaceMergeTag(_matchedProduct[0].product_name, PERSON_NAME_MERGE_TAG, person_name));
                } else {
                    _matchedProduct = filteredProducts.filter((item) => item.product_name.includes(data.product_heading));
                    if (_matchedProduct && _matchedProduct.length > 0) {
                        formRef.current.setFieldValue("product", _matchedProduct[0]);
                        formRef.current.setFieldValue("product_id", _matchedProduct[0].product_id);
                        formRef.current.setFieldValue("product_name", replaceMergeTag(_matchedProduct[0].product_name, PERSON_NAME_MERGE_TAG, person_name));
                    }
                }
        }
    }, [filteredProducts, data]);

    return (
        <div className={classes.container}>
            {showAlert && <Alert severity='error' message={errorMsg}/>}
            <div className={classes.title}>
                <div className={classes.flexCenter}>
                    <h2 className={classes.headline}>
						<span className={classes.headlineSpan}>
							<span>Purchase</span>
							<span className={classes.opportunityID}>{data.purchase_id}</span>
						</span>
                    </h2>
                </div>
            </div>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 50,
                    }}
                >
                    <CircularProgress/>
                </div>
            ) : (
                <div>
                    {data ? (
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                company_id: "",
                                company_cvr: "",
                                company_name: "",
                                company_address_1: "",
                                company_zip_code: "",
                                company_city: "",
                                company_address_country: "",
                                purchase_id: "",
                                purchase_price: "",
                                purchase_type: "",
                                purchase_ean: "",
                                purchase_bookkeeper_email: "",
                                purchase_po_number: "",
                                product_id: "",
                                product_name: "",
                                agent: "",
                                product_heading: "",
                                product: null,
                                split_invoices: [
                                    {
                                        invoice_payment_terms: "",
                                        invoice_amount: "",
                                        invoice_sent_date: moment(),
                                        invoice_due_date: moment(),
                                    },
                                ],
                                invoice_number: "",
                                invoice_status: "",
                                invoice_payment_terms_type: "",
                                document_type: "",
                                invoice_deadline_date: "",
                                invoice_amount_currency: "",
                                invoice_layout: "",
                                invoice_layout_title: "",
                                additional_notes: "",
                                invoice_notes: "",
                                invoice_footer_notes: "",
                                user_economic_id: props.user?.user_details?.user_economic_id || '',
                            }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.purchase_ean && !values.purchase_bookkeeper_email) {
                                    errors.purchase_ean = "Required";
                                    errors.purchase_bookkeeper_email = "Required";
                                }

                                if (
                                    values.purchase_bookkeeper_email &&
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.purchase_bookkeeper_email)
                                ) {
                                    errors.purchase_bookkeeper_email = "Invalid email address";
                                }

                                if (values.purchase_ean && values.purchase_ean.length < 13) {
                                    errors.purchase_ean = "EAN should be atleast 13 characters";
                                }

                                if (!values.company_cvr) {
                                    errors.company_cvr = "Required";
                                }

                                if (!values.company_name) {
                                    errors.company_name = "Required";
                                }

                                if (!values.invoice_layout) {
                                    errors.invoice_layout = "Required";
                                }

                                if (!values.product_heading) {
                                    errors.product_heading = "Required";
                                }

                                if (!values.product || !values.product.product_id || !values.product.product_name) {
                                    errors.product = "Required";
                                }

                                if (!values.product_name) {
                                    errors.product_name = "Required";
                                }

                                if (!values.split_invoices || !values.split_invoices.length) {
                                    errors.split_invoices = "At least one invoice must be added"; // Check if array is empty
                                } else {
                                    // Validate each item in the split_invoices array
                                    errors.split_invoices = [];
                                    var total = 0;

                                    values.split_invoices.forEach((invoice, index) => {
                                        if (invoice.invoice_amount) {
                                            total += parseFloat(invoice.invoice_amount);
                                        }

                                        if (!invoice.invoice_payment_terms || !invoice.invoice_amount || !invoice.invoice_sent_date
                                            || (invoice.invoice_payment_terms === PAYMENT_TERM_BETALES_INDEN && !invoice.invoice_due_date)) {
                                            errors.split_invoices[index] = {};

                                            if (!invoice.invoice_payment_terms) {
                                                errors.split_invoices[index].invoice_payment_terms = "Required";
                                            }
                                            if (!invoice.invoice_amount) {
                                                errors.split_invoices[index].invoice_amount = "Required";
                                            }

                                            if (!invoice.invoice_sent_date) {
                                                errors.split_invoices[index].invoice_sent_date = "Required";
                                            }

                                            if (invoice.invoice_payment_terms === PAYMENT_TERM_BETALES_INDEN && !invoice.invoice_due_date) {
                                                errors.split_invoices[index].invoice_due_date = "Required";
                                            }
                                        }
                                    });

                                    if (!errors.split_invoices || !errors.split_invoices.length) {
                                        delete errors.split_invoices;
                                    }

                                    if (total > values.purchase_price) {
                                        errors.purchase_price = "Total amount of split invoices is greater than product price";
                                    } else if (total < values.purchase_price) {
                                        errors.purchase_price = "Total amount of split invoices is less than product price";
                                    }
                                }
                                return errors;
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                if (clickedButton === "savendraftorder") {
                                    setTimeout(() => {
                                        handleSubmit(values, setSubmitting);
                                    }, 2000); // 2-second delay
                                } else {
                                    handleSubmit(values, setSubmitting);
                                }
                            }}
                        >
                            {({isSubmitting, values, setFieldValue, setFieldTouched, errors, submitCount}) => (
                                <Form>
                                    <Grid container spacing={4}>
                                        <Grid item xs={8}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <Card
                                                        style={{
                                                            padding: "12px",
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Typography component='h1' variant='h6' align='left'
                                                                        color='textPrimary' gutterBottom>
                                                                Customer Details
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            sx={{
                                                                marginBottom: "15px",
                                                            }}
                                                            spacing={1}
                                                        >
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    required
                                                                    name='company_cvr'
                                                                    type='text'
                                                                    label='Customer Number'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    required
                                                                    name='company_name'
                                                                    type='text'
                                                                    label='Customer Name'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='company_address_1'
                                                                    type='text'
                                                                    label='Address'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='company_zip_code'
                                                                    type='text'
                                                                    label='Zip Code'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='company_city'
                                                                    type='text'
                                                                    label='City'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='company_address_country'
                                                                    type='text'
                                                                    label='Country'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Card
                                                        style={{
                                                            padding: "12px",
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Typography component='h1' variant='h6' align='left'
                                                                        color='textPrimary' gutterBottom>
                                                                Bookkeeping Details</Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            sx={{
                                                                marginBottom: "15px",
                                                            }}
                                                            spacing={1}
                                                        >
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='purchase_bookkeeper_email'
                                                                    type='text'
                                                                    label='Bookkeeper e-mail'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='purchase_ean'
                                                                    type='text'
                                                                    label='EAN'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    name='purchase_po_number'
                                                                    type='text'
                                                                    label='PO Number'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Tooltip
                                                                                    title="PO number will be reflected in invoice comments">
                                                                                    <IconButton>
                                                                                        <Info/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onChange={(e) => prepareInvoiceNotes(e.target.value, values.invoice_notes, formRef.current.values.split_invoices)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Card
                                                        style={{
                                                            padding: "12px",
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Typography component='h1' variant='h6' align='left'
                                                                        color='textPrimary' gutterBottom>
                                                                Payment Terms
                                                                <Tooltip
                                                                    title={`Split Invoice Can not be send as Performa/Order`}>
                                                                    <IconButton><Info/></IconButton>
                                                                </Tooltip>

                                                            </Typography>
                                                        </Grid>
                                                        <FieldArray name='split_invoices'>
                                                            {({push, remove, form}) => (
                                                                <>
                                                                    {values.split_invoices.map((item, index) => (
                                                                        <Grid
                                                                            container
                                                                            sx={{
                                                                                marginBottom: "15px",
                                                                            }}
                                                                            spacing={1}
                                                                            key={index}
                                                                        >
                                                                            <Grid item xs={2}>
                                                                                <Autocomplete
                                                                                    name={`split_invoices[${index}].invoice_payment_terms`}
                                                                                    disablePortal
                                                                                    margin='normal'
                                                                                    size='small'
                                                                                    id='combo-box'
                                                                                    options={paymentTermsOptions}
                                                                                    value={
                                                                                        paymentTermsOptions.find(
                                                                                            (option) =>
                                                                                                option.name ===
                                                                                                values.split_invoices[index].invoice_payment_terms
                                                                                        ) || null
                                                                                    } // Set the initial value
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                                    onChange={(_event, newValue) => {
                                                                                        const selected = newValue ? newValue.name : "";
                                                                                        form.setFieldValue(
                                                                                            `split_invoices[${index}].invoice_payment_terms`,
                                                                                            selected
                                                                                        );
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        form.setFieldTouched(
                                                                                            `split_invoices[${index}].invoice_payment_terms`,
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <MaterialTextField
                                                                                            {...params}
                                                                                            label='Payment Terms*'
                                                                                            error={
                                                                                                form.submitCount > 0 &&
                                                                                                !!form.errors.split_invoices?.[index]
                                                                                                    ?.invoice_payment_terms
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "0.75rem",
                                                                                        margin: "4px 14px 0px",
                                                                                    }}
                                                                                >
                                                                                    <ErrorMessage
                                                                                        name={`split_invoices[${index}].invoice_payment_terms`}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <Field
                                                                                    name={`invoice_percentage[${index}]`}
                                                                                    type='text'
                                                                                    label='Percentage'
                                                                                    variant='outlined'
                                                                                    margin='normal'
                                                                                    size='small'
                                                                                    inputProps={{
                                                                                        min: 0,
                                                                                        max: 100,
                                                                                        step: 1,
                                                                                    }}
                                                                                    InputLabelProps={{shrink: true}}
                                                                                    style={{
                                                                                        marginTop: "0px",
                                                                                    }}
                                                                                    component={TextField}
                                                                                    onInput={(event) => handleInvoicePercentageInput(event, index)}
                                                                                    onKeyDown={(event) => {
                                                                                        const {key, target} = event;
                                                                                        const {value} = target;

                                                                                        if (key !== "Backspace") {
                                                                                            let newPercent = parseFloat(value + key);
                                                                                            // Prevent entering values greater than 100
                                                                                            if (parseFloat(value + key) > 100) {
                                                                                                event.preventDefault();
                                                                                                return;
                                                                                            }

                                                                                            let totalPercent = values.invoice_percentage.reduce(
                                                                                                (sum, currentValue, currentIndex) => {
                                                                                                    if (currentIndex === index) {
                                                                                                        return sum;
                                                                                                    }
                                                                                                    return sum + parseFloat(currentValue);
                                                                                                },
                                                                                                0
                                                                                            );

                                                                                            if (totalPercent + newPercent > 100) {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <Field
                                                                                    name={`split_invoices[${index}].invoice_amount`}
                                                                                    type='text'
                                                                                    label='Amount*'
                                                                                    variant='outlined'
                                                                                    margin='normal'
                                                                                    component={TextField}
                                                                                    size='small'
                                                                                    style={{
                                                                                        marginTop: "0px",
                                                                                    }}
                                                                                    onInput={(event) => handleInvoiceAmountInput(event, index)}
                                                                                    onKeyDown={(event) => {
                                                                                        const {key, target} = event;
                                                                                        const {value} = target;

                                                                                        if (key !== "Backspace") {
                                                                                            let newAmount = parseFloat(value + key);
                                                                                            // Prevent entering values greater than values.purchase_price
                                                                                            if (parseFloat(value + key) > values.purchase_price) {
                                                                                                event.preventDefault();
                                                                                                return;
                                                                                            }

                                                                                            let totalAmount = values.split_invoices.reduce(
                                                                                                (sum, currentValue, currentIndex) => {
                                                                                                    if (currentIndex === index) {
                                                                                                        return sum;
                                                                                                    }
                                                                                                    return (
                                                                                                        sum + parseFloat(currentValue.invoice_amount)
                                                                                                    );
                                                                                                },
                                                                                                0
                                                                                            );

                                                                                            if (
                                                                                                parseFloat(totalAmount) + newAmount >
                                                                                                values.purchase_price
                                                                                            ) {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        } else {
                                                                                            if (index === 0) {
                                                                                                let newAmount = value ? value.toString() : '';
                                                                                                newAmount = parseFloat(newAmount.slice(0, -1));
                                                                                                if (isNaN(newAmount)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDateFns}>
                                                                                    <DatePicker
                                                                                        name={`split_invoices[${index}].invoice_sent_date`}
                                                                                        label='Invoice Date*'
                                                                                        inputFormat='dd/MM/yyyy'
                                                                                        className='custom-datepicker'
                                                                                        value={values.split_invoices[index].invoice_sent_date} // Set the initial value
                                                                                        onChange={(newValue) => {
                                                                                            form.setFieldValue(
                                                                                                `split_invoices[${index}].invoice_sent_date`,
                                                                                                newValue
                                                                                            );
                                                                                        }}
                                                                                        renderInput={(startProps) => (
                                                                                            <React.Fragment>
                                                                                                <MaterialTextField
                                                                                                    {...startProps}
                                                                                                    sx={{
                                                                                                        width: "100%",
                                                                                                    }}
                                                                                                    padding={{
                                                                                                        top: "1px",
                                                                                                    }}
                                                                                                    error={
                                                                                                        form.submitCount > 0 &&
                                                                                                        !!form.errors.split_invoices?.[index]
                                                                                                            ?.invoice_sent_date
                                                                                                    }
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        disabled={disableInvoiceForm(isSubmitting, values)}
                                                                                    />
                                                                                    <div
                                                                                        style={{
                                                                                            color: "red",
                                                                                            fontSize: "0.75rem",
                                                                                            margin: "4px 14px 0px",
                                                                                        }}
                                                                                    >
                                                                                        <ErrorMessage
                                                                                            name={`split_invoices[${index}].invoice_sent_date`}
                                                                                        />
                                                                                    </div>
                                                                                </LocalizationProvider>
                                                                            </Grid>
                                                                            {
                                                                                item.invoice_payment_terms === PAYMENT_TERM_BETALES_INDEN &&
                                                                                <Grid item xs={2}>
                                                                                    <LocalizationProvider
                                                                                        dateAdapter={AdapterDateFns}>
                                                                                        <DatePicker
                                                                                            name={`split_invoices[${index}].invoice_due_date`}
                                                                                            label='Due Date*'
                                                                                            inputFormat='dd/MM/yyyy'
                                                                                            className='custom-datepicker'
                                                                                            value={values.split_invoices[index].invoice_due_date} // Set the initial value
                                                                                            onChange={(newValue) => {
                                                                                                form.setFieldValue(
                                                                                                    `split_invoices[${index}].invoice_due_date`,
                                                                                                    newValue
                                                                                                );
                                                                                            }}
                                                                                            renderInput={(startProps) => (
                                                                                                <React.Fragment>
                                                                                                    <MaterialTextField
                                                                                                        {...startProps}
                                                                                                        sx={{
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                        padding={{
                                                                                                            top: "1px",
                                                                                                        }}
                                                                                                        error={
                                                                                                            form.submitCount > 0 &&
                                                                                                            !!form.errors.split_invoices?.[index]
                                                                                                                ?.invoice_due_date
                                                                                                        }
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            disabled={disableInvoiceForm(isSubmitting, values)}
                                                                                        />
                                                                                        <div
                                                                                            style={{
                                                                                                color: "red",
                                                                                                fontSize: "0.75rem",
                                                                                                margin: "4px 14px 0px",
                                                                                            }}
                                                                                        >
                                                                                            <ErrorMessage
                                                                                                name={`split_invoices[${index}].invoice_sent_date`}
                                                                                            />
                                                                                        </div>
                                                                                    </LocalizationProvider>
                                                                                </Grid>

                                                                            }
                                                                            {index !== 0 &&
                                                                            (values.invoice_status === INVOICE_STATUS_PENDING ||
                                                                                values.document_type === INVOICE_TYPE_ORDER) ? (
                                                                                <Grid
                                                                                    item
                                                                                    xs={1}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "flex-start",
                                                                                    }}
                                                                                >
                                                                                    <IconButton
                                                                                        color='error'
                                                                                        onClick={() => handleDeleteClick(remove, index)}
                                                                                    >
                                                                                        <Delete/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Grid>
                                                                    ))}
                                                                    {(values.invoice_status === INVOICE_STATUS_PENDING || values.document_type === INVOICE_TYPE_ORDER) && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                variant='contained'
                                                                                color='primary'
                                                                                onClick={() => handleSplitInvoiceClick(push)}
                                                                                disabled={
                                                                                    isSubmitting ||
                                                                                    disableSplitInvoice ||
                                                                                    values.document_type === INVOICE_TYPE_ORDER
                                                                                }
                                                                            >
                                                                                Split Invoice
                                                                            </Button>
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Card
                                                        style={{
                                                            padding: "12px",
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Typography component='h1' variant='h6' align='left'
                                                                        color='textPrimary' gutterBottom>
                                                                Product Details
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            sx={{
                                                                marginBottom: "15px",
                                                            }}
                                                            spacing={1}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Field
                                                                    name='agent'
                                                                    type='text'
                                                                    label='Agent'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Field
                                                                    name='product_heading'
                                                                    type='text'
                                                                    label='Product Heading*'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Autocomplete
                                                                    name='product'
                                                                    label='Product*'
                                                                    disablePortal
                                                                    size='small'
                                                                    id='combo-box'
                                                                    options={filteredProducts}
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                    value={values.product}
                                                                    onChange={(_event, newValue) => {
                                                                        if (newValue) {
                                                                            let person_name = data?.person_firstname + " " + data?.person_lastname;

                                                                            setFieldValue('product', newValue);
                                                                            setFieldValue("product_id", newValue.product_id);
                                                                            setFieldValue("product_name", replaceMergeTag(newValue.product_name, PERSON_NAME_MERGE_TAG, person_name));
                                                                        } else {
                                                                            setFieldValue('product', '');
                                                                            setFieldValue("product_id", '');
                                                                            setFieldValue("product_name", '');
                                                                        }
                                                                    }}
                                                                    getOptionLabel={(option) => option.product_id ? option.product_id + ' -- ' + option.product_name : ''}
                                                                    onBlur={() => {
                                                                        setFieldTouched("product", true);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <MaterialTextField
                                                                            {...params}
                                                                            label='Product*'
                                                                            error={submitCount > 0 && errors.product}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <>
                                                                                        {params.InputProps.endAdornment}
                                                                                        <Tooltip
                                                                                            title={`Add merge tag ${PERSON_NAME_MERGE_TAG}`}>
                                                                                            <IconButton><Info/></IconButton>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "0.75rem",
                                                                        margin: "4px 14px 0px",
                                                                    }}
                                                                >
                                                                    <ErrorMessage name={"product"}/>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Field
                                                                    component='textarea'
                                                                    rows='5'
                                                                    size='large'
                                                                    name='product_name'
                                                                    style={{
                                                                        width: "100%",
                                                                        padding: "10px",
                                                                        fontSize: "16px",
                                                                        borderColor: "#c4c4c4",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                    placeholder="Product Details"
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                />
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "0.75rem",
                                                                        margin: "4px 14px 0px",
                                                                    }}
                                                                >
                                                                    <ErrorMessage name={"product_name"}/>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Field
                                                                    name='purchase_price'
                                                                    type='text'
                                                                    label='Price'
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    fullWidth={true}
                                                                    component={TextField}
                                                                    size='small'
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Card
                                                        style={{
                                                            padding: "12px",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            sx={{
                                                                marginBottom: "15px",
                                                            }}
                                                            spacing={1}
                                                        >
                                                            <Grid item xs={4}>
                                                                <Grid>
                                                                    <Typography
                                                                        component='h1'
                                                                        variant='h6'
                                                                        align='left'
                                                                        color='textPrimary'
                                                                        gutterBottom
                                                                    >
                                                                        Invoice Comments

                                                                        <Tooltip
                                                                            title='Invoice comments + PO Number + information of split invoices "Del x ud y"'
                                                                            arrow
                                                                            placement='top'>
                                                                            <IconButton
                                                                                aria-label='invoice notes'
                                                                                aria-controls='menu-appbar'
                                                                                aria-haspopup='true'
                                                                                sx={{
                                                                                    color: "gray"
                                                                                }}
                                                                            >
                                                                                <Info/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Typography>

                                                                </Grid>
                                                                <Field
                                                                    component='textarea'
                                                                    rows='5'
                                                                    size='large'
                                                                    name='invoice_notes'
                                                                    style={{
                                                                        width: "100%",
                                                                        padding: "10px",
                                                                        fontSize: "16px",
                                                                        borderColor: "#c4c4c4",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Grid>
                                                                    <Typography
                                                                        component='h1'
                                                                        variant='h6'
                                                                        align='left'
                                                                        color='textPrimary'
                                                                        gutterBottom
                                                                    >
                                                                        Footer Comments
                                                                        <Tooltip
                                                                            title='Text will appear in the invoice footer.'
                                                                            arrow
                                                                            placement='top'>
                                                                            <IconButton
                                                                                aria-label='text2'
                                                                                aria-controls='menu-appbar'
                                                                                aria-haspopup='true'
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    color: "gray"
                                                                                }}
                                                                            >
                                                                                <Info/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Typography>
                                                                </Grid>
                                                                <Field
                                                                    component='textarea'
                                                                    rows='5'
                                                                    size='large'
                                                                    name='invoice_footer_notes'
                                                                    style={{
                                                                        width: "100%",
                                                                        padding: "10px",
                                                                        fontSize: "16px",
                                                                        borderColor: "#c4c4c4",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Grid>
                                                                    <Typography
                                                                        component='h1'
                                                                        variant='h6'
                                                                        align='left'
                                                                        color='textPrimary'
                                                                        gutterBottom
                                                                    >
                                                                        Internal Comments
                                                                        <Tooltip
                                                                            title='This text will not appear on the invoice.'
                                                                            arrow
                                                                            placement='top'>
                                                                            <IconButton
                                                                                aria-label='internal comments'
                                                                                aria-controls='menu-appbar'
                                                                                aria-haspopup='true'
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    color: "gray"
                                                                                }}
                                                                            >
                                                                                <Info/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Typography>
                                                                </Grid>
                                                                <Field
                                                                    component='textarea'
                                                                    rows='5'
                                                                    size='large'
                                                                    name='additional_notes'
                                                                    style={{
                                                                        width: "100%",
                                                                        padding: "10px",
                                                                        fontSize: "16px",
                                                                        borderColor: "#c4c4c4",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                    placeholder=''
                                                                    disabled={disableInvoiceForm(isSubmitting, values)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <>
                                                                    <Autocomplete
                                                                        name='invoice_layout'
                                                                        label='Layout*'
                                                                        disablePortal
                                                                        size='small'
                                                                        id='combo-box'
                                                                        options={layoutOptions}
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        disabled={disableInvoiceForm(isSubmitting, values)}
                                                                        value={
                                                                            layoutOptions.find(
                                                                                (option) => option.layoutNumber === values.invoice_layout
                                                                            ) || null
                                                                        }
                                                                        onChange={(_event, newValue) => {
                                                                            const layoutNumber = newValue ? newValue.layoutNumber : "";
                                                                            setFieldValue("invoice_layout", layoutNumber);

                                                                            const layoutTitle = newValue ? newValue.name : "";
                                                                            setFieldValue("invoice_layout_title", layoutTitle);
                                                                        }}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onBlur={() => {
                                                                            setFieldTouched("invoice_layout", true);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <MaterialTextField
                                                                                {...params}
                                                                                label='Layout*'
                                                                                error={submitCount > 0 && errors.invoice_layout}
                                                                            />
                                                                        )}>

                                                                    </Autocomplete>
                                                                    <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "0.75rem",
                                                                            margin: "4px 14px 0px",
                                                                        }}
                                                                    >
                                                                        <ErrorMessage name={"invoice_layout"}/>
                                                                    </div>
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Card
                                                        style={{
                                                            padding: "12px",
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Typography component='h1' variant='h6' align='left'
                                                                        color='textPrimary' gutterBottom>
                                                                Invoice Details
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='purchase_id'
                                                                type='text'
                                                                label='Purchase ID'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='purchase_type'
                                                                type='text'
                                                                label='Purchase Type'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='purchase_creation_time'
                                                                type='text'
                                                                label='Purchase Date'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='invoice_creation_date'
                                                                type='text'
                                                                label='Invoice Creation Date'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='invoice_status'
                                                                type='text'
                                                                label='Document Status'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='invoice_payment_terms_type'
                                                                type='text'
                                                                label='Payment Terms Type'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='document_type'
                                                                type='text'
                                                                label='Document Type'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='invoice_draft_date'
                                                                type='text'
                                                                label='Draft Date'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='invoice_deadline_date'
                                                                type='text'
                                                                label='Deadline Date'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name='invoice_amount_currency'
                                                                type='text'
                                                                label='Amount Currency'
                                                                variant='outlined'
                                                                margin='normal'
                                                                fullWidth={true}
                                                                component={TextField}
                                                                size='small'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "right",
                                                    }}
                                                >
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            color='error'
                                                            onClick={() => {
                                                                navigate("/finance");
                                                            }}
                                                            style={{
                                                                margin: "10px",
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='submit'
                                                            disabled={disableSaveAndDraftButton(isSubmitting, values, props.user)}
                                                            onClick={() => handleButtonClick("save")}
                                                            style={{
                                                                margin: "10px",
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                    {
                                                        !values.invoice_number ?
                                                            <>
                                                            <Grid item>
                                                                <Button
                                                                    type='submit'
                                                                    variant='contained'
                                                                    color='primary'
                                                                    disabled={disableSaveAndDraftButton(isSubmitting, values, props.user)}
                                                                    onClick={() => {
                                                                        setFieldValue("document_type", INVOICE_TYPE_ORDER);
                                                                        mergeSplitInvoices(true, values);
                                                                        handleButtonClick("savendraftorder")
                                                                    }
                                                                    }
                                                                    style={{
                                                                        margin: "10px",
                                                                    }}
                                                                >
                                                                    {values.document_type === INVOICE_TYPE_ORDER &&
                                                                    values.invoice_number &&
                                                                    values.invoice_status === INVOICE_STATUS_DRAFT
                                                                        ? "Update In Economic"
                                                                        : "Draft Order"}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    type='submit'
                                                                    variant='contained'
                                                                    color='primary'
                                                                    disabled={disableSaveAndDraftButton(isSubmitting, values, props.user)}
                                                                    onClick={() => handleButtonClick("savendraft")}
                                                                    style={{
                                                                        margin: "10px",
                                                                    }}
                                                                >
                                                                    Draft Invoice
                                                                </Button>
                                                            </Grid>
                                                        </> : <>
                                                            {
                                                                values.document_type === INVOICE_TYPE_ORDER &&
                                                                values.invoice_status === INVOICE_STATUS_DRAFT &&
                                                                <Grid item>
                                                                    <Button
                                                                        type='submit'
                                                                        variant='contained'
                                                                        color='primary'
                                                                        disabled={disableSaveAndDraftButton(isSubmitting, values, props.user)}
                                                                        onClick={() => {
                                                                            setFieldValue("document_type", INVOICE_TYPE_ORDER);
                                                                            mergeSplitInvoices(true, values);
                                                                            handleButtonClick("savendraftorder")
                                                                        }
                                                                        }
                                                                        style={{
                                                                            margin: "10px",
                                                                        }}
                                                                    >
                                                                       Update In Economic
                                                                    </Button>
                                                                </Grid>
                                                            }
                                                            </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: "",
    invoice: {},
    user: accountSelectors.user(state),
    foodConsiderations: contactsSelectors.foodConsiderations(state),
    canDraftInvoice: accountSelectors.canDraftInvoice(state),
});

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <InvoiceDetails {...props} params={params} navigate={navigate}/>;
}

export default connect(mapStateToProps)(withStyles(useStyles)(WithNavigate));
